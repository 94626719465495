import { Box, Grid, Typography } from "@mui/material";
import * as React from "react";
import { StructureCard } from "../cards/StructureCard";
import { useTranslation } from "react-i18next";
import { ProjectComponent } from "./ProjectComponent";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { getUser } from "../common/redux/userAction";
import {
    DisponibiliteStructure,
    UserStructureResponse,
} from "./model/StructureModel";
import {
    getEmptyAvatarStructure,
    getPictureFromProfilImage,
} from "../utils/FormatUtils";

export const ProjectPage = () => {
    const userSelector: UserStructureResponse = useAppSelector(
        (state: RootState) => state.user.data
    );
    const projectSelector: any | undefined = useAppSelector(
        (state: RootState) => state.projects
    );

    const [account, setAccount] =
        React.useState<UserStructureResponse>(userSelector);
    const [isDisplayPopUpCreateProject, setIsDisplayPopUpCreateProject] =
        React.useState(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const CompanyCalled = (props: DisponibiliteStructure, i: number) => {
        return (
            <Grid
                item
                xl={5}
                key={i}
                m={1}
                xs={6}
                lg={6}
                sx={{ width: "min-content" }}
            >
                <StructureCard
                    {...props}
                    indexOfDisponibility={i}
                    key={i}
                    profilImageUrl={
                        userSelector.profileImage
                            ? getPictureFromProfilImage(
                                  userSelector.profileImage.contentUrl
                              )
                            : getEmptyAvatarStructure()
                    }
                />
            </Grid>
        );
    };

    const numberOfProjects = () => {
        return userSelector.disponibilities.length;
    };

    React.useEffect(() => {
        dispatch(getUser(userSelector?.id)).then(() =>
            setIsDisplayPopUpCreateProject(true)
        );
        setAccount(userSelector);
        if (projectSelector.isCreateProject) {
            setIsDisplayPopUpCreateProject(true);
        }
    }, [dispatch, projectSelector, userSelector]);

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <Box sx={{ justifySelf: "center", mt: 6 }}>
                    <ProjectComponent />
                    <Typography
                        sx={{
                            mt: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {t("project.on")}
                        <span
                            style={{
                                backgroundColor: "#3d3d3d",
                                padding: 3,
                                color: "white",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "2px",
                                marginRight: "2px",
                            }}
                        >
                            {" " + numberOfProjects() + " "}
                        </span>{" "}
                        {t("project.on.going")}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}
                    >
                        {account
                            ? userSelector.disponibilities.map((dispo, i) => {
                                  return CompanyCalled(dispo, i);
                              })
                            : null}
                    </Box>
                </Box>
                <Box sx={{ gridArea: "card", ml: 6 }}></Box>
            </Box>
        </>
    );
};
