export const PolitiqueCookie = `Dernière mise à jour : 23/11/2023

Bienvenue sur l'application mobile Smart Santé, développée et exploitée par Smart Santé ("nous", "notre", "nos"). Cette politique relative aux cookies explique comment nous utilisons les cookies et les technologies similaires lorsque vous utilisez notre application mobile.

En utilisant l'application mobile Smart Santé, vous consentez à l'utilisation de cookies conformément à la présente politique. Si vous n'acceptez pas l'utilisation de ces cookies, veuillez désactiver les cookies en suivant les instructions fournies dans cette politique, à moins que les cookies soient nécessaires.

1. Qu'est-ce qu'un cookie ?

Un cookie est un petit fichier texte qui est stocké sur votre appareil lorsque vous visitez un site Web ou utilisez une application mobile. Les cookies ont plusieurs fonctions, notamment la collecte d'informations sur la manière dont vous utilisez l'application, la mémorisation de vos préférences, et l'amélioration de votre expérience utilisateur.

2. Types de cookies que nous utilisons

Nous utilisons les types de cookies suivants dans l'application mobile Smart Santé :

Cookies essentiels : Ces cookies sont nécessaires au bon fonctionnement de l'application mobile. Ils vous permettent de naviguer dans l'application et d'utiliser ses fonctionnalités de base.

Cookies analytiques : Ces cookies nous aident à comprendre comment vous utilisez l'application, à quel point elle est performante, et à identifier les domaines à améliorer. Les informations collectées sont anonymes et agrégées.

Cookies de préférences : Ces cookies mémorisent vos préférences, telles que la langue que vous avez choisie, et rendent ainsi votre expérience utilisateur plus personnelle.

3. Contrôle des cookies

Vous pouvez contrôler et/ou supprimer les cookies comme vous le souhaitez. Vous pouvez supprimer tous les cookies déjà présents sur votre appareil et vous pouvez configurer la plupart des navigateurs pour les empêcher d'être placés. Cependant, si vous désactivez les cookies, certaines fonctionnalités de l'application mobile Smart Santé pourraient ne pas fonctionner correctement.

4. Modifications de la politique relative aux cookies

Nous nous réservons le droit de modifier la présente politique relative aux cookies à tout moment. Toute modification substantielle sera signalée dans l'application. Nous vous encourageons à consulter régulièrement cette politique pour vous tenir informé de la manière dont nous utilisons les cookies.

Pour toute question concernant notre politique relative aux cookies, veuillez nous contacter à l'adresse suivante : contact@smartsanteconseil.fr.

Merci d'utiliser l'application mobile Smart Santé.`;
