import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HTTPApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(HTTPApi)
    .init({
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: [
                "querystring",
                "cookie",
                "localStorage",
                "navigator",
                "htmlTag",
                "path",
                "subdomain",
            ],
            lookupQuerystring: "lng",
            lookupCookie: "i18next",
            lookupLocalStorage: "i18nextLng",
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
            caches: ["localStorage", "cookie"],
            excludeCacheFor: ["cimode"],
        },
    });

export default i18n;
