import React from "react";
import styled from "@emotion/styled";
import {
    Button,
    List,
    Link,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { logout } from "../../login/redux/authAction";
import {
    AvatarResponse,
    UserStructureResponse,
} from "../../structures/model/StructureModel";
import { getEmptyAvatarStructure } from "../../utils/FormatUtils";
import { RouteUtils } from "../../utils/RouteUtils";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { RootState } from "../redux/store";
import { getUser } from "../redux/userAction";
import { storageService } from "../service/storageService";
import { useLocation } from "react-router-dom";
import { RouteApp } from "../model/RouteApp";
import { language, LanguageType } from "../../utils/LanguageUtils";
import { t } from "i18next";

const Logo = require("../../assets/Logo.PNG");

export default function Header() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [avatar, setAvatar] = React.useState<AvatarResponse | undefined>(
        undefined
    );
    const userSelector: UserStructureResponse = useAppSelector(
        (state: RootState) => state.user.data
    );
    const location = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_URL;
    const idUser = storageService.getIdWithToken();
    const lang: LanguageType = language();

    React.useEffect(() => {
        if (idUser && idUser?.length > 1) dispatch(getUser(idUser));
    }, [dispatch, idUser]);

    React.useEffect(() => {
        if (userSelector.profileImage) {
            setAvatar(userSelector.profileImage);
        }
    }, [userSelector]);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (type: string) => {
        if (type === "Deconnexion") {
            dispatch(logout());
            navigate(RouteUtils.LOGIN);
        } else if (type === "Profil") {
            navigate(RouteUtils.PROFIL);
        }
        setAnchorEl(null);
    };

    return (
        <Box>
            <AppBar
                position="static"
                sx={{
                    backgroundColor: "#3d3d3d",
                    textAlign: "center",
                }}
            >
                <Toolbar
                    disableGutters
                    sx={{ justifyContent: "space-around", gap: "3rem" }}
                >
                    {/* Logo Container */}
                    <Button
                        sx={{
                            height: "100px",
                            width: "20%",
                        }}
                    >
                        <Box
                            component="img"
                            sx={{ maxHeight: 62 }}
                            alt="Smart Santé"
                            className="center"
                            src={Logo}
                            onClick={() => navigate(RouteUtils.DASHBOARD)}
                        />
                    </Button>

                    {/* NavBar Container */}
                    {!location.pathname.includes("/login") &&
                        !location.pathname.includes("/reset") &&
                        !location.pathname.includes("/signup") && (
                            <List
                                component="nav"
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "40%",
                                    height: "auto",
                                    p: 0,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}
                            >
                                <StyledListItemBtn
                                    onClick={() => navigate(RouteApp.DASHBOARD)}
                                    selected={
                                        location.pathname === RouteApp.DASHBOARD
                                    }
                                >
                                    <ListItemText
                                        primary={lang.navbar.dashboard}
                                    />
                                </StyledListItemBtn>

                                <StyledListItemBtn
                                    onClick={() => navigate(RouteApp.SEARCH)}
                                    selected={
                                        location.pathname === RouteApp.SEARCH
                                    }
                                >
                                    <ListItemText
                                        primary={lang.navbar.personnel}
                                    />
                                </StyledListItemBtn>

                                <StyledListItemBtn
                                    onClick={() =>
                                        navigate(RouteApp.PROFESSIONNELS)
                                    }
                                    selected={
                                        location.pathname ===
                                        RouteApp.PROFESSIONNELS
                                    }
                                >
                                    <ListItemText
                                        primary={lang.navbar.contact}
                                    />
                                </StyledListItemBtn>

                                <ListItemButton
                                    component={Link}
                                    onClick={() =>
                                        navigate(RouteApp.MY_PROJECTS)
                                    }
                                    selected={
                                        location.pathname ===
                                        RouteApp.MY_PROJECTS
                                    }
                                    sx={{
                                        backgroundColor: "#3d3d3d",
                                        placeContent: "center",
                                        display: "grid",
                                        width: "100px",
                                        textAlign: "center",
                                        color: "#c0c0c0",
                                        justifyContent: "center",
                                        height: "100px",
                                        ":hover, :focus": {
                                            backgroundColor: "white",
                                            color: "#353839",
                                        },
                                    }}
                                >
                                    <ListItemText
                                        sx={{ fontSize: "3ex" }}
                                        primary={lang.navbar.projet}
                                    />
                                </ListItemButton>

                                {/* Profile Container */}
                                <StyledListItemBtn
                                    onClick={() => navigate("/profil")}
                                    selected={
                                        location.pathname ===
                                        RouteApp.MY_ACCOUNT
                                    }
                                >
                                    <ListItemText
                                        primary={lang.navbar.profil}
                                    />
                                </StyledListItemBtn>
                            </List>
                        )}
                    {userSelector.id && userSelector.societe && (
                        <Box
                            className="right"
                            sx={{
                                width: "auto",
                                display: "flex",
                                justifyContent: "center",
                                height: "100px",
                            }}
                        >
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                                sx={{
                                    justifyContent: "space-between",
                                    width: "auto",
                                    borderRadius: 0,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    width={"fit-content"}
                                >
                                    {userSelector.societe}
                                </Typography>
                                {
                                    <Avatar
                                        src={
                                            userSelector.profileImage
                                                ? url! +
                                                  userSelector.profileImage
                                                      .contentUrl
                                                : getEmptyAvatarStructure()
                                        }
                                        sx={{
                                            borderRadius: "50%",
                                            width: 54,
                                            height: 54,
                                            ml: 2,
                                            mr: 15,
                                        }}
                                    ></Avatar>
                                }
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => handleClose("Profil")}>
                                    Profil
                                </MenuItem>
                                <MenuItem
                                    onClick={() => handleClose("Deconnexion")}
                                >
                                    {t("button.profile.disconnect")}
                                </MenuItem>
                            </Menu>
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}

const StyledListItemBtn = styled(ListItemButton)({
    backgroundColor: "#3d3d3d",
    placeContent: "center",
    width: "100px",
    textAlign: "center",
    display: "grid",
    height: "100px",
    color: "#c0c0c0",
    cursor: "pointer",

    "&:hover, :focus, :hover": {
        backgroundColor: "white",
        color: "#353839",
    },

    "&$selected:focus": {
        backgroundColor: "inherit",
    },
});
