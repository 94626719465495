import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
    Box,
    Button,
    DialogContent,
    IconButton,
    Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { language, LanguageType } from "../../utils/LanguageUtils";
import { useTranslation } from "react-i18next";

interface PopupProps {
    open: boolean;
    onClose: () => void;
    message?: string;
    arrayOfMessage?: string[];
    title?: string;
    onValidation?: () => void;
    titleButtonValidation?: string;
    onCancel?: () => void;
    titleButtonCancel?: string;
    credentials?: string;
}

export const Modal = (props: PopupProps) => {
    const lang: LanguageType = language();
    const message = lang.modalSmart;
    const { t } = useTranslation();
    return (
        <Dialog open={props.open} className="popup-background">
            <DialogTitle className="popup-header">
                {props.title}
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    className="popup-close-button"
                >
                    <HighlightOffIcon
                        sx={{ color: "white" }}
                        fontSize="medium"
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {props.message ? (
                    <p>{props.message}</p>
                ) : (
                    <Typography sx={{ whiteSpace: "pre-line" }}>
                        {message}
                    </Typography>
                )}
                {props.arrayOfMessage && (
                    <div>
                        {props.arrayOfMessage.map((e, i) => {
                            return <p key={i}>{e}</p>;
                        })}
                    </div>
                )}

                {props.credentials ? (
                    <p>{props.credentials}</p>
                ) : (
                    <Typography sx={{ whiteSpace: "pre-line" }}>
                        {t("forgot.password.description.modal")}
                    </Typography>
                )}

                <Box
                    sx={{ textAlign: "center", mt: 3 }}
                    className="popup-actions-button"
                >
                    {props.onCancel && (
                        <Button
                            onClick={props.onCancel}
                            sx={{ mr: 3 }}
                            color="error"
                            size="large"
                            variant="outlined"
                            className="popup-cancel-button"
                        >
                            {props.titleButtonCancel}
                        </Button>
                    )}
                    {props.onValidation && (
                        <Button
                            onClick={props.onValidation}
                            color="success"
                            size="large"
                            variant="contained"
                            className="popup-validate-button"
                        >
                            {props.titleButtonValidation}
                        </Button>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};
