import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RouteAPI, api } from "../../common/service/apiService";
import { CreateDisponibiliteStructure, DisponibiliteStructure } from "../model/StructureModel";
import { dropPoject, getProjects, newProject } from "./projectSlice";
import { ResponseViolations } from "./signUpAction";




export const createProject = createAsyncThunk<DisponibiliteStructure , CreateDisponibiliteStructure>(
  "project/create", async(data: CreateDisponibiliteStructure, thunkAPI: any) => {
    try {
      const response:DisponibiliteStructure = await api(RouteAPI.DISPONIBILITE_STRUCTURE, 'POST', data).then((res) => res.data)
      thunkAPI.dispatch(newProject(response))
      return response
      }
    catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError<ResponseViolations>);
      }
  }
)


export const deleteProject = createAsyncThunk<any , string>(
  "project/delete", async(id: string, thunkAPI: any) => {
    try {
      const response:any = await api(RouteAPI.DISPONIBILITE_STRUCTURE + '/' + id, 'DELETE', {}).then((res) => res.data)
      thunkAPI.dispatch(dropPoject(response))
      return response
      }
    catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError<ResponseViolations>);
      }
  }
)

export const getProjectsById = createAsyncThunk<any , string>(
  "project/get", async(id: string, thunkAPI: any) => {
    try {
      const response:any = await api(RouteAPI.STRUCTURES + '/' + id, 'GET', {}).then((res) => res.data)
      thunkAPI.dispatch(getProjects(response))
      return response
      }
    catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError<ResponseViolations>);
      }
  }
)



// export const signUp = createAsyncThunk<UserStructureResponse, SignUpData>(
//   "structure/create", async(signUpData: SignUpData, thunkAPI: any) => {
//     try {
//       const response: UserStructureResponse = await api(RouteAPI.SIGN_UP, 'POST', signUpData).then((res) => res.data)
//       thunkAPI.dispatch(setSignUpStructure(response))
//       return response
//       }
//     catch (error) {
//         return thunkAPI.rejectWithValue(error as AxiosError<ResponseViolations>);
//       }
//   }
// )

