import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseMessage } from "../model/ResponseMessage";
import { UserStructureResponse } from "../../structures/model/StructureModel";
import { api, RouteAPI } from "../service/apiService";


export const getUser = createAsyncThunk<UserStructureResponse, string>(
  "users/getUser",
  async (idUser, thunkAPI) => {
      try {
          const response = await api(RouteAPI.STRUCTURES + '/' + idUser, 'GET');
          return response.data as UserStructureResponse;
      } catch (error) {
          return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
      }
  }
)

export const deleteUser = createAsyncThunk<UserStructureResponse>(
    "api/account",
    async (_, thunkAPI) => {
        try {
            const response = await api(RouteAPI.DELETE_USER, 'DELETE');
            return response.data as UserStructureResponse;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
  )
