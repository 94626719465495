import { UserProfessionnelResponse } from "./ProjectModel";

export interface UserStructureResponse {
  societe: string;
  siret: string;
  disponibilities: DisponibiliteStructure[];
  id: string;
  email: string;
  roles: string[];
  enabled: boolean;
  telephone: string;
  profileImage: AvatarResponse;
  description: string;
}

export interface CreateDisponibiliteStructure {
  ville: string;
  profession: string;
  selection: string[];
  description?: string;
  type: string;
  dateDemarrage: string | null;
  dateFin?: string | null;
}

export interface DisponibiliteStructure {
  ville: string;
  profession: string[];
  id: string;
  mois: string[];
  selection: string[];
  description: string;
  type: string;
  dateDemarrage: string;
  dateFin: string;
  enabled: boolean;
  interested: [] | null;
  isInterested: {
    status: string;
  };
  profilImageUrl?: string;
  indexOfDisponibility?: number;
  fulfilled?: boolean;
}

export interface ProfessionnelInterestedByStructure {
  Professionnel: UserProfessionnelResponse;
  status: string;
}

export const emptyUserStructure: UserStructureResponse = {
  societe: "",
  siret: "",
  disponibilities: [],
  id: "",
  email: "",
  roles: [""],
  enabled: false,
  telephone: "",
  profileImage: { contentUrl: "", id: "" },
  description: "",
};

export interface AvatarResponse {
  id: string;
  contentUrl: string;
}
