export const ConditionGenerale = `1. Acceptation des Conditions
En utilisant l'application mobile Smart Santé ("l'Application"), vous acceptez pleinement et sans réserve les présentes Conditions Générales d'Utilisation ("CGU"). Si vous n'acceptez pas ces CGU, veuillez ne pas utiliser l'Application.

2. Description du Service
Smart Santé fournit une plateforme mobile facilitant la mise en relation entre les structures de santé et le personnel soignant. L'entreprise Smart Santé supervise l'utilisation de l'Application pour garantir une expérience sécurisée et efficace.

3. Utilisation de l'Application
3.1 Inscription et Compte Utilisateur

Pour utiliser certaines fonctionnalités de l'Application, vous devrez créer un compte utilisateur. Vous êtes responsable de maintenir la confidentialité de vos informations d'identification et de notifier immédiatement Smart Santé de toute utilisation non autorisée de votre compte.

3.2 Utilisation Autorisée

Vous vous engagez à utiliser l'Application conformément à toutes les lois et réglementations applicables. Vous ne devez pas utiliser l'Application à des fins illégales, frauduleuses ou nuisibles.

3.3 Contenu Utilisateur

En tant qu'utilisateur, vous acceptez que tout contenu que vous publiez sur l'Application respecte les droits de propriété intellectuelle, la vie privée et toutes les lois applicables. Smart Santé se réserve le droit de supprimer tout contenu jugé inapproprié.

4. Responsabilités de Smart Santé
4.1 Sécurité des Données

Smart Santé s'engage à prendre des mesures raisonnables pour sécuriser vos données. Cependant, aucune mesure de sécurité n'est infaillible, et Smart Santé ne peut garantir la sécurité totale de vos données.

4.2 Disponibilité du Service

Smart Santé s'efforce de maintenir une disponibilité continue de l'Application, mais ne peut garantir une accessibilité sans interruption en raison de facteurs indépendants de sa volonté.

5. Modifications des CGU
Smart Santé se réserve le droit de modifier les présentes CGU à tout moment. Les modifications seront publiées sur l'Application, et il vous incombe de les consulter régulièrement. En continuant à utiliser l'Application après de telles modifications, vous acceptez les CGU révisées.

6. Résiliation
Smart Santé se réserve le droit de résilier votre accès à l'Application en cas de violation des présentes CGU.

7. Loi Applicable
Les présentes CGU sont régies par les lois en vigueur dans le lieu où Smart Santé exerce ses activités.`;
