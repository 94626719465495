import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseMessage } from "../model/ResponseMessage";
import { User } from "../model/User";
import { getUser } from "./userAction";
import { emptyUserStructure, UserStructureResponse } from "../../structures/model/StructureModel";
import { initialStateReducer, IStateReducer } from "../model/IStateReducer";


const initialUsersState: IStateReducer<UserStructureResponse> = {
  ...initialStateReducer,
  data: emptyUserStructure
}

export const userSlice = createSlice({
  name: 'users',
  initialState: initialUsersState,
  reducers: {
    setUserConnected: (state, action: PayloadAction<UserStructureResponse>) => {
      state.data = action.payload
    },
    // usersSetResponseMessage: (state, action: PayloadAction<ResponseMessage | undefined>) => {
    //   state.data.responseMessage = action.payload
    // },
    disconnectUser: (state, action: PayloadAction<any | undefined>) => {
      state.data = emptyUserStructure
    },
    setIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload
    }
  },
  extraReducers(builder) {
    // **************** REINITIALISATION DU MDP *****************
    builder.addCase(getUser.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
  },
})

// Action creators are generated for each case reducer function
export const { setUserConnected, setIsSuccess, disconnectUser } = userSlice.actions

export default userSlice