import React from "react";
import { FunctionComponent } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PolitiqueConfidentialite } from "../utils/PolitiqueConfidentialite";
import { ConditionGenerale } from "../utils/ConditionGenerale";
import { PolitiqueCookie } from "../utils/PolitiqueCookie";
import { MentionLegal } from "../utils/MentionLegal";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

export const LegacyPage: FunctionComponent = () => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box sx={{ width: "70%", ml: "auto", mr: "auto", mt: 10 }}>
      <Typography
        variant="h3"
        sx={{ color: "#3d3d3d", textAlign: "center", mb: "100px" }}
      >
        Informations Légales
      </Typography>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ m: "1%", backgroundColor: "white" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography variant="h5" color="#3d3d3d">
            Condition générales d'utilisations
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            sx={{ color: "#3d3d3d", whiteSpace: "pre-wrap" }}
          >
            {ConditionGenerale}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{ m: "1%", backgroundColor: "white" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography variant="h5" color="#3d3d3d">
            Politique de Confidentialité
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            sx={{ color: "#3d3d3d", whiteSpace: "pre-wrap" }}
          >
            {PolitiqueConfidentialite}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ m: "1%", backgroundColor: "white" }}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography variant="h5" color="#3d3d3d">
            Politique relative aux cookies
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            sx={{ color: "#3d3d3d", whiteSpace: "pre-wrap" }}
          >
            {PolitiqueCookie}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ m: "1%", backgroundColor: "white" }}
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          aria-controls="panel4d-content"
          id="panel4d-header"
        >
          <Typography variant="h5" color="#3d3d3d">
            Mentions Légales
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            sx={{ color: "#3d3d3d", whiteSpace: "pre-wrap" }}
          >
            {MentionLegal}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
