import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

interface EmptyCardProps {
    message: string;
    component: string;
}

export const EmptyCard: FunctionComponent<EmptyCardProps> = ({
    message,
    component,
}) => {
    const navigate = useNavigate();

    const redirectToProjects = () => {
        navigate(component);
    };

    return (
        <Card
            sx={{
                mt: 3,
                color: "#776B5D",
                borderRadius: "30px",
                display: "inline-flex",
                width: "320px",
                height: "240px",
                border: "2px dashed #776B5D",
            }}
            elevation={1}
        >
            <CardActionArea onClick={redirectToProjects}>
                <CardContent
                    sx={{
                        placeItems: "center",
                        textAlign: "center",
                        cursor: "pointer",
                    }}
                >
                    <Typography fontSize={26} color={"#776B5D"}>
                        {message}
                    </Typography>
                    <AddCircleOutlineIcon fontSize="large"></AddCircleOutlineIcon>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
