import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Stack,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import ImageUploading, { ImageType } from "react-images-uploading";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../common/components/CustomSnackbar";
import { Modal } from "../common/components/Modal";
import PasswordInput from "../common/components/PasswordInput";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { RouteAPI } from "../common/service/apiService";
import {
    AvatarResponse,
    UserStructureResponse,
    emptyUserStructure,
} from "../structures/model/StructureModel";
import { postAvatar, signUp } from "../structures/redux/signUpAction";
import { clearPicture } from "../structures/redux/signUpSlice";
import { getPictureFromProfilImage } from "../utils/FormatUtils";
import { language, LanguageType } from "../utils/LanguageUtils";
import { RouteUtils } from "../utils/RouteUtils";
import { SignUpData } from "./model/SignUpData";
import { RouteApp } from "../common/model/RouteApp";
import { useTranslation } from "react-i18next";

export default function SignUpPage() {
    const [snackBarMessage, setSnackBarMessage] = React.useState<
        any | undefined
    >(undefined);
    const [isSnackBarOpen, setIsSnackBarOpen] = React.useState<boolean>(false);
    const [isSuccesSignUp, setIsSuccesSignUp] = React.useState<boolean>(false);
    const [avatar, setAvatar] = React.useState<AvatarResponse>({
        contentUrl: "",
        id: "",
    });
    const [image, setImage] = React.useState<any>([]);
    const [displayAvatar, setDisplayAvatar] = React.useState<boolean>(false);
    const [legacyChecked, setLegacyChecked] = React.useState<boolean>(false);
    const [errorLegacy, setErrorLegacy] = React.useState<boolean>(false);
    const [userInfos, setUserInfos] =
        React.useState<UserStructureResponse>(emptyUserStructure);

    const dispatch = useAppDispatch();
    const initAvatar = { contentUrl: "", id: "" };
    const structureSelector = useAppSelector(
        (state: RootState) => state.signUp
    );
    const { t } = useTranslation();
    const lang: LanguageType = language();
    const navigate = useNavigate();

    function handleCloseSnackbar(
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) {
        setSnackBarMessage(undefined);
    }

    const onSuccesSignUp = () => {
        navigate(RouteUtils.LOGIN);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (legacyChecked !== true) {
            setErrorLegacy(true);
            return;
        }
        setErrorLegacy(false);
        const form = new FormData(event.currentTarget);
        let data = {
            email: form.get("email") as string,
            plainPassword: form.get("password") as string,
            telephone: form.get("phone") as string,
            siret: form.get("siret") as string,
            societe: form.get("societe") as string,
            description: form.get("societe") as string,
        };
        if (avatar.id.length > 0)
            Object.assign(
                data,
                Object(avatar.id.length) > 1 && {
                    profileImage: RouteAPI.GET_MEDIA_OBJECTS_ID + avatar.id,
                }
            );

        dispatch(signUp(data as SignUpData));
    };

    const deleteAvatar = () => {
        setAvatar(initAvatar);
        dispatch(clearPicture());
        setDisplayAvatar(false);
    };

    const onChange = (image: ImageType) => {
        if (image[0]) {
            dispatch(postAvatar(image[0].file));
            setImage(image);
            setDisplayAvatar(true);
        }
    };
    useEffect(() => {
        if (structureSelector.data.profileImage) {
            setAvatar(structureSelector.data.profileImage);
        }
        if (structureSelector.error) {
            setSnackBarMessage(structureSelector.error.response?.data);
            setIsSnackBarOpen(true);
        }
        if (structureSelector.data) {
            setUserInfos(structureSelector.data);
        }
        return setIsSnackBarOpen(false);
    }, [structureSelector]);

    useEffect(() => {
        if (userInfos.id) setIsSuccesSignUp(true);
    }, [userInfos]);

    useEffect(() => {
        setIsSnackBarOpen(false);
        setSnackBarMessage(undefined);
    }, []);

    const legacyCheckboxLink = () => {
        return (
            <span onClick={() => navigate(RouteApp.LEGACY)}>
                <u>{t("sign.up.condition")}</u>
            </span>
        );
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "#3d3d3d" }}>
                    <VpnKeyOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("sign.up.registration")}
                </Typography>
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 3 }}
                >
                    <Box sx={{ mb: 4 }}>
                        <ImageUploading
                            value={image}
                            onChange={(img) => onChange(img)}
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                                errors,
                            }) => (
                                // write your building UI
                                <Stack className="upload__image-wrapper">
                                    <Button
                                        variant="outlined"
                                        sx={{ alignSelf: "center" }}
                                        style={
                                            isDragging
                                                ? { color: "red" }
                                                : undefined
                                        }
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                        {t("sign.up.avatar")}
                                    </Button>
                                    {displayAvatar &&
                                        avatar.contentUrl.length > 0 && (
                                            <div
                                                className="image-item"
                                                style={{
                                                    textAlign: "center",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Avatar
                                                    src={
                                                        avatar.contentUrl
                                                            ? getPictureFromProfilImage(
                                                                  avatar.contentUrl
                                                              )
                                                            : undefined
                                                    }
                                                    sx={{
                                                        width: 94,
                                                        height: 94,
                                                        m: 1,
                                                    }}
                                                    variant="square"
                                                    sizes="large"
                                                />
                                                <div className="image-item__btn-wrapper">
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() =>
                                                            onImageUpdate(0)
                                                        }
                                                        sx={{ mr: 1 }}
                                                    >
                                                        {t(
                                                            "sign.up.avatar.button.modify"
                                                        )}
                                                    </Button>
                                                    <Button
                                                        sx={{ px: 2.8 }}
                                                        variant="outlined"
                                                        onClick={() =>
                                                            deleteAvatar()
                                                        }
                                                    >
                                                        {t(
                                                            "sign.up.avatar.button.delete"
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    {structureSelector.isExtraField &&
                                        avatar.contentUrl.length <= 0 && (
                                            <Box sx={{ alignSelf: "center" }}>
                                                <InfinitySpin
                                                    color="#A93F58"
                                                    width="200"
                                                />
                                            </Box>
                                        )}
                                </Stack>
                            )}
                        </ImageUploading>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="organization"
                                name="societe"
                                required
                                fullWidth
                                id="societe"
                                label={t("sign.up.company")}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="siret"
                                label={t("sign.up.number")}
                                name="siret"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                type="number"
                                id="phone"
                                label={t("sign.up.phone")}
                                name="phone"
                                autoComplete="tel-national"
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                        {
                                            display: "none",
                                        },
                                    "& input[type=number]": {
                                        MozAppearance: "textfield",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label={t("auth.email")}
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <PasswordInput />
                        </Grid>
                    </Grid>
                    <FormControl
                        required
                        error={errorLegacy}
                        component="fieldset"
                        sx={{ mt: 3 }}
                        variant="standard"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={legacyChecked}
                                    onChange={() =>
                                        setLegacyChecked(!legacyChecked)
                                    }
                                    name="gilad"
                                />
                            }
                            label={
                                <>
                                    <span>{t("sign.up.therms")}</span>{" "}
                                    {legacyCheckboxLink()}
                                </>
                            }
                        />
                        {errorLegacy && (
                            <FormHelperText>
                                {t("sign.up.error")}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="error"
                    >
                        {t("sign.up.button.register")}
                    </Button>
                    <CustomSnackbar
                        open={isSnackBarOpen}
                        handleCloseSnackbar={handleCloseSnackbar}
                        message={
                            snackBarMessage?.detail
                                ? snackBarMessage?.detail
                                : ""
                        }
                        severity={
                            snackBarMessage?.status === 200
                                ? "success"
                                : "error"
                        }
                        position={{ vertical: "bottom", horizontal: "center" }}
                        autoHideDuration={
                            snackBarMessage?.status === 200 ? 3000 : 6000
                        }
                    />
                    {isSnackBarOpen || snackBarMessage ? (
                        <Alert severity="error" className="errorMessage">
                            {snackBarMessage?.detail
                                ? snackBarMessage?.detail
                                : ""}
                        </Alert>
                    ) : null}
                    <Grid
                        container
                        justifyContent="flex-end"
                        marginBottom="1rem"
                    >
                        <Grid item>
                            <Link href={RouteUtils.LOGIN} variant="body2">
                                {t("sign.up.connection")}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Modal
                open={isSuccesSignUp}
                title={lang.titleModalSignUpSuccess}
                message={lang.messageModalSignUpSucces}
                onClose={() => onSuccesSignUp()}
                onValidation={() => onSuccesSignUp()}
                titleButtonValidation={"Valider"}
            />
        </Container>
    );
}
