import { CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import HandShakeIcon from "../assets/handshakeWhite.png";
import { Modal } from "../common/components/Modal";
import { RouteApp } from "../common/model/RouteApp";
import { useAppDispatch } from "../common/redux/reduxHooks";
import {
    DisponibiliteProfessionnel,
    emptyUserProfessionnel,
} from "../search/model/Disponibilite";
import { postSmart } from "../search/redux/searchAction";
import { getEmptyAvatarProfessionnel } from "../utils/FormatUtils";
import { useTranslation } from "react-i18next";

const green = "linear-gradient(to right, #58b2a6, #58b2a6)";
const nightGradient = "linear-gradient(to right, #003366, #090254)";
const greenGradient =
    "linear-gradient(to right top, #4f6f52, #668468, #7e9a7e, #96b195, #afc8ad)";
export const Item = styled(Typography)(() => ({
    background: greenGradient,
    boxShadow:
        "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
    padding: 10,
    margin: 3,
    borderRadius: "5px",
    color: "white",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    fontSize: "16px",
    width: "90px",
}));

export const ItemValidated = styled(Typography)(() => ({
    backgroundColor: "#58b2a6",
    background: green,
    padding: 15,
    margin: 3,
    borderRadius: "10px",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    fontSize: "17px",
    width: "auto",
    maxWidth: "fit-content",
    wordWrap: "break-word",
}));

export const ItemNight = styled(Typography)(() => ({
    backgroundColor: "#B95757",
    background: nightGradient,
    padding: 10,
    margin: 3,
    borderRadius: "10px",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    fontSize: "16px",
    width: "90px",
    maxWidth: "fit-content",
    wordWrap: "break-word",
}));

export const refStatus = {
    accepted: "APPROUVED",
    refused: "REFUSED",
    wait: "WAIT",
};

export const defaultProfessionnelProps: DisponibiliteProfessionnel = {
    account: emptyUserProfessionnel,
    dateDemarrage: new Date(),
    dateFin: new Date(),
    departements: [""],
    description: "",
    selection: ["", ""],
    enabled: false,
    id: "",
    isInterested: null,
    isMock: true,
};

export const WorkerCard: FunctionComponent<DisponibiliteProfessionnel> = (
    props: DisponibiliteProfessionnel
) => {
    const [isDisplayPopupSmart, setIsDisplayPopupSmart] =
        useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    function capitalizeFirstLetter(string: string) {
        if (string) {
            return string[0].toUpperCase() + string.slice(1);
        }
    }

    const smart = () => {
        dispatch(postSmart(props.id)).then((res: any) => {
            if (!res.error) {
                setIsDisplayPopupSmart(true);
            }
        });
    };

    const onValidatePopUpSmart = () => {
        setIsDisplayPopupSmart(false);
        navigate(RouteApp.PROFESSIONNELS);
    };

    let config = {
        color: "#58b2a6",
        message: t("message.smart"),
        logo: true,
        logoIcon: HandShakeIcon,
    };
    // #3d3d3d : Smart Envoyé
    // #58b2a6 : Je smart !

    if (props.isInterested) {
        if (props.isInterested.status === refStatus.wait) {
            config = {
                color: "#3d3d3d",
                message: t("message.send.smart"),
                logo: false,
                logoIcon: HandShakeIcon,
            };
        } else if (props.isInterested.status === refStatus.accepted) {
            config = {
                color: "#58b2a6",
                message: t("message.accepted.smart"),
                logo: false,
                logoIcon: HandShakeIcon,
            };
        } else if (props.isInterested.status === refStatus.refused) {
            config = {
                color: "#757575",
                message: t("message.refused.smart"),
                logo: false,
                logoIcon: HandShakeIcon,
            };
        }
    }
    if (props.fulfilled) {
        config = {
            color: "#58b2a6",
            message: t("message.provided.smart"),
            logo: false,
            logoIcon: HandShakeIcon,
        };
    }

    return (
        <>
            <Card
                sx={{
                    mt: 3,
                    borderRadius: "15px",
                    display: "inline-flex",
                    maxWidth: "340px",
                    minWidth: "300px",
                    ":hover": {
                        boxShadow: 20, // theme.shadows[20]
                    },
                }}
                elevation={5}
            >
                <CardActionArea disabled={!config.logo}>
                    <CardHeader
                        subheaderTypographyProps={{ paragraph: true }}
                        avatar={
                            <img
                                src={
                                    props.profilImageUrl ??
                                    getEmptyAvatarProfessionnel()
                                }
                                alt="Profile"
                                style={{
                                    borderRadius: "50%",
                                    width: 120,
                                    height: 120,
                                }}
                            />
                        }
                        title={
                            <div>
                                <Typography variant="body2">
                                    {props.account?.nom[0] +
                                        "." +
                                        props.account?.prenom}
                                </Typography>
                            </div>
                        }
                        subheader={
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    height: "120px",
                                }}
                            >
                                <Typography variant="body2">
                                    {props.description}
                                </Typography>
                            </div>
                        }
                    />
                    <CardContent
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        <div
                            style={{
                                height: "120px",
                                width: "100%",
                            }}
                        >
                            {props.account &&
                                props.account?.profession.map((e, i) => {
                                    return (
                                        <Typography
                                            key={i}
                                            mb={1}
                                            sx={{ width: "100%" }}
                                        >
                                            {t(`professions.${e}`)}
                                        </Typography>
                                    );
                                })}
                            {props.departements &&
                                props.departements.map((e, i) => {
                                    return (
                                        <Typography
                                            key={i}
                                            mb={1}
                                            sx={{ width: "100%" }}
                                        >
                                            {e}
                                        </Typography>
                                    );
                                })}
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {props.selection &&
                                props.selection.map((e, i) => {
                                    return (
                                        <div
                                            key={i}
                                            style={{
                                                alignSelf: "center",
                                            }}
                                        >
                                            {e !== t("select.night") ? (
                                                <Item>
                                                    {capitalizeFirstLetter(e)}
                                                </Item>
                                            ) : (
                                                <ItemNight>
                                                    {capitalizeFirstLetter(e)}
                                                </ItemNight>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                    </CardContent>
                    <Grid
                        container
                        columns={16}
                        bgcolor={"#3d3d3d"}
                        textAlign={"center"}
                        pt={1}
                        pb={1}
                    >
                        <Grid
                            item
                            xs={7.9}
                            sx={{ borderRight: "0.1em solid white" }}
                        >
                            <Typography
                                color={"white"}
                                sx={{ fontWeight: "bold" }}
                            >
                                {new Date(
                                    props.dateDemarrage
                                ).toLocaleDateString("fr")}
                            </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={7.9}>
                            <Typography
                                color={"white"}
                                sx={{ fontWeight: "bold" }}
                            >
                                {props.dateFin
                                    ? new Date(
                                          props.dateFin
                                      ).toLocaleDateString("fr")
                                    : t("select.date.undetermined")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        columns={16}
                        bgcolor={config.color}
                        textAlign={"center"}
                        pt={1}
                        pb={1}
                        onClick={() => smart()}
                    >
                        <Grid item xs={15.8}>
                            <Typography
                                variant="h6"
                                color={"white"}
                                sx={{
                                    fontWeight: "bold",
                                    justifyContent: "space-between",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                            >
                                {config.message}{" "}
                                {config.logo && (
                                    <img
                                        src={config.logoIcon}
                                        alt="Handshake Smart Santé"
                                        style={{
                                            height: "20%",
                                            width: "20%",
                                            marginLeft: "10px",
                                        }}
                                    ></img>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
            {props.isInterested === null && (
                <Modal
                    open={isDisplayPopupSmart}
                    title={t("message.congratulations.smart")}
                    onClose={() => setIsDisplayPopupSmart(false)}
                    onValidation={() => onValidatePopUpSmart()}
                    titleButtonValidation={t("button.modal.valid")}
                ></Modal>
            )}
        </>
    );
};
