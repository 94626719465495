import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ProfessionnelInterestedByStructure } from '../../structures/model/StructureModel';
import { getEmptyAvatarProfessionnel, getPictureFromProfilImage } from '../../utils/FormatUtils';
import { ItemValidated } from '../../cards/WorkerCard';



export const SmartAcceptedCard = (props: ProfessionnelInterestedByStructure) => {
  const { Professionnel } = props;


  return (
    <Grid item xs={12} md={6}>
   
        <Card sx={{ display: 'flex' }}>
          <CardContent sx={{ flex: 1 }}>
          
            <Typography component="h2" variant="h5">
              {Professionnel.nom}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {Professionnel.prenom}
            </Typography>
            
                {Professionnel.profession.map((v, i) => {
                    return <Typography variant="subtitle1" key={i} > {v}</Typography>
                })}
              
            
            <Typography variant="subtitle1" color="primary">
              {Professionnel.telephone}
            </Typography>
            <ItemValidated sx={{ml: 'auto'}}>Smart Validé !</ItemValidated>
          </CardContent>
          
          <CardMedia
            component="img"
            sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
            image={Professionnel.profileImage ? getPictureFromProfilImage(Professionnel.profileImage.contentUrl) : getEmptyAvatarProfessionnel()}
            alt={'profileImage Smart Sante'}
          />
        </Card>

    </Grid>
  );
}