import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStateReducer, initialStateReducer } from "../../common/model/IStateReducer";
import { AxiosError } from "axios";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { AvatarResponse, UserStructureResponse, emptyUserStructure } from "../model/StructureModel";
import { ResponseViolations, postAvatar, signUp } from "./signUpAction";
import { createProject } from "./projectAction";




const initialUserStructure: UserStructureResponse = emptyUserStructure

const initialState: IStateReducer<UserStructureResponse> = {
  ...initialStateReducer,
  data: initialUserStructure
};


export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    newProject: (state, action) => {
      state.data = action.payload.data;
    },
    dropPoject: (state) => {
      state.isExtraField = false;
    },
    getProjects: (state, action) => {
      state.data = action.payload.data
    }
    
    
  },
  extraReducers(builder) {

     builder.addCase(postAvatar.pending, (state) => {
       state.isLoader = true;
       state.isExtraField = true
       state.isSuccess = false;
       state.error = undefined;
       state.requestType = 'POST';
     });
     builder.addCase(postAvatar.fulfilled, (state, action: PayloadAction<AvatarResponse>) => {
       state.data.profileImage = action.payload;
       state.isExtraField = false
       state.isLoader = false;
       state.isSuccess = true;
       state.requestType = 'POST';
     });
     builder.addCase(postAvatar.rejected, (state, action: PayloadAction<any>) => {
       state.isLoader = false;
       state.isExtraField = false
       state.isSuccess = false;
       state.error = action.payload as AxiosError<ResponseViolations>;
       state.requestType = 'POST';
     });

      builder.addCase(signUp.pending, (state) => {
        state.isLoader = true;
        state.isSuccess = false;
        state.error = undefined;
        state.requestType = 'POST';
     });
     builder.addCase(signUp.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseViolations>;
      state.requestType = 'POST';
    });

    
    builder.addCase(createProject.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.isCreateProject = false;
      state.requestType = 'POST';
   });
   builder.addCase(createProject.fulfilled, (state, action) => {
    state.isLoader = false;
    state.isSuccess = true;
    state.isCreateProject = true;
    state.requestType = 'POST';
  });
  builder.addCase(createProject.rejected, (state, action) => {
    state.isLoader = false;
    state.isSuccess = false;
    state.isCreateProject = false;
    state.error = action.payload as AxiosError<ResponseViolations>;
    state.requestType = 'POST';
  });
    // builder.addCase(postAvatar.fulfilled, (state, action) => {
    //   state.data.avatar = action.payload;
    //   state.isLoader = false;
    //   state.isSuccess = true;
    //   state.requestType = 'POST';
    // });
    // builder.addCase(postAvatar.rejected, (state, action: PayloadAction<any>) => {
    //   state.isLoader = false;
    //   state.isSuccess = false;
    //   state.error = action.payload as AxiosError<ResponseMessage>;
    //   state.requestType = 'POST';
    // });
    // // **************** RECUPERATION D'UNE COMMANDE *****************
    // builder.addCase(getOrderById.pending, (state) => {
    //   state.isLoader = true;
    //   state.isSuccess = false;
    //   state.error = undefined;
    //   state.requestType = 'GET';
    // });
    // builder.addCase(getOrderById.fulfilled, (state, action) => {
    //   state.data.order = action.payload;
    //   state.isLoader = false;
    //   state.isSuccess = true;
    //   state.requestType = 'GET';
    // });
    // builder.addCase(getOrderById.rejected, (state, action: PayloadAction<any>) => {
    //   state.isLoader = false;
    //   state.isSuccess = false;
    //   state.error = action.payload as AxiosError<ResponseMessage>;
    //   state.requestType = 'GET';
    // });
    // // **************** MAJ D'UNE COMMANDE *****************
    // builder.addCase(updateOrderById.pending, (state) => {
    //   state.isLoader = true;
    //   state.isSuccess = false;
    //   state.error = undefined;
    //   state.requestType = 'PUT';
    // });
    // builder.addCase(updateOrderById.fulfilled, (state, action) => {
    //   state.data.responseMessage = action.payload;
    //   state.isLoader = false;
    //   state.isSuccess = true;
    //   state.requestType = 'PUT';
    // });
    // builder.addCase(updateOrderById.rejected, (state, action: PayloadAction<any>) => {
    //   state.isLoader = false;
    //   state.isSuccess = false;
    //   state.error = action.payload as AxiosError<ResponseMessage>;
    //   state.requestType = 'PUT';
    // });
  }
})

// Action creators are generated for each case reducer function
export const { newProject, dropPoject, getProjects} = projectSlice.actions

export default projectSlice