import { configureStore } from "@reduxjs/toolkit";
import authReducer from '../../login/redux/authSlice'
import signUpSlice from "../../structures/redux/signUpSlice";
import userSlice from "./userSlice";
import projectSlice from "../../structures/redux/projectSlice";
import searchSlice from "../../search/redux/searchSlice";
import professionnelSlice from "../../professionnels/redux/professionnelSlice";
import { profilSlice } from "../../profil/redux/profilSlice";

export const store: any = configureStore({
  reducer: {
    auth: authReducer.reducer,
    signUp: signUpSlice.reducer,
    user: userSlice.reducer,
    projects: projectSlice.reducer,
    search: searchSlice.reducer,
    professionnel: professionnelSlice.reducer,
    profil: profilSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export default store;