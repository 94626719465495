import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Role } from "../model/User";
// import { useAppSelector } from "../redux/reduxHooks";
// import { RootState } from "../redux/store";

import Header from "./Header";

import { Dashboard } from "../../dashboard/DashboardPage";
import { ForgotPage } from "../../login/ForgotPage";
import LoginPage from "../../login/LoginPage";
import { ResetPasswordPage } from "../../login/ResetPasswordPage";
import SignUpPage from "../../login/SignUpPage";
import { ProfessionnelPage } from "../../professionnels/ProfessionnelPage";
import { ProfilPage } from "../../profil/ProfilPage";
import { LegacyPage } from "../../legacy/LegacyPage";
import { SearchPage } from "../../search/SearchPage";
import { ProjectDetailsPage } from "../../structures/ProjectDetailsPage";
import { ProjectPage } from "../../structures/ProjectPage";
// import { UserStructureResponse } from "../../structures/model/StructureModel";
import { RouteApp } from "../model/RouteApp";
// import { HeaderLeft } from "./HeaderLeft";
import { NotFoundPage } from "./NotFoundPage";
import PrivateRoute from "./PrivateRoute";

const MainRoutes: FunctionComponent = () => {
    // const userSelector: UserStructureResponse = useAppSelector(
    //   (state: RootState) => state.user.data
    // );

    return (
        <>
            <Header />
            {/* {userSelector.id && <HeaderLeft />} */}
            <Routes>
                <>
                    <Route
                        path={"/"}
                        element={<Navigate to={RouteApp.LOGIN} />}
                    ></Route>
                    <Route path={RouteApp.LOGIN} element={<LoginPage />} />
                    <Route path={RouteApp.SIGNUP} element={<SignUpPage />} />
                    <Route
                        path={RouteApp.FORGOT_PASSWORD}
                        element={<ForgotPage />}
                    />
                    <Route
                        path={RouteApp.RESET_PASSWORD + "/:token"}
                        element={<ResetPasswordPage />}
                    />

                    {/* <Route path={RouteApp.RESET_PASSWORD + ':token'} element={<ResetPasswordPage />} /> */}
                </>

                <Route
                    index
                    path={RouteApp.DASHBOARD}
                    element={
                        <PrivateRoute roles={[Role.User, Role.Structure]}>
                            <Dashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteApp.PROFESSIONNELS}
                    element={
                        <PrivateRoute roles={[Role.User, Role.Structure]}>
                            <ProfessionnelPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteApp.SEARCH}
                    element={
                        <PrivateRoute roles={[Role.User, Role.Structure]}>
                            <SearchPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteApp.MY_PROJECTS}
                    element={
                        <PrivateRoute roles={[Role.User, Role.Structure]}>
                            <ProjectPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={RouteApp.MY_PROJECTS_DETAILS + "/:idProject"}
                    element={
                        <PrivateRoute roles={[Role.User, Role.Structure]}>
                            <ProjectDetailsPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={RouteApp.MY_ACCOUNT}
                    element={
                        <PrivateRoute roles={[Role.User, Role.Structure]}>
                            <ProfilPage />
                        </PrivateRoute>
                    }
                />
                {
                    <Route
                        path={RouteApp.LEGACY}
                        element={<LegacyPage />}
                    ></Route>
                }
                {<Route path="*" element={<NotFoundPage />}></Route>}
            </Routes>
        </>
    );
};

export default MainRoutes;
