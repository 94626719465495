import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import { EmptyCard } from "../cards/EmptyCard";
import { StructureCard } from "../cards/StructureCard";
import { WorkerCard } from "../cards/WorkerCard";
import { RouteApp } from "../common/model/RouteApp";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { getInterestProfessionnels } from "../professionnels/redux/professionnelAction";
import { DisponibiliteProfessionnel } from "../search/model/Disponibilite";
import {
    DisponibiliteStructure,
    UserStructureResponse,
} from "../structures/model/StructureModel";
import {
    getEmptyAvatarStructure,
    getPictureFromProfilImage,
} from "../utils/FormatUtils";
import { language, LanguageType } from "../utils/LanguageUtils";

import "./DashboardPage.css";

export const Dashboard = () => {
    const [count, setCount] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [personnelsMatched, setPersonnelMatched] = React.useState<
        DisponibiliteProfessionnel[] | []
    >([]);

    const professionnelMatchedSelector = useAppSelector(
        (state: RootState) => state.professionnel
    );
    const userSelector: UserStructureResponse = useAppSelector(
        (state: RootState) => state.user.data
    );

    const idUser = useAppSelector((state: RootState) => state.user.data.id);
    const dispatch = useAppDispatch();
    const lang: LanguageType = language();
    const { t } = useTranslation();

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex + 3;
            return newIndex < userSelector.disponibilities.length
                ? newIndex
                : prevIndex;
        });
    };

    const handleBackClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex - 3;
            return newIndex >= 0 ? newIndex : prevIndex;
        });
    };

    useEffect(() => {
        dispatch(getInterestProfessionnels(idUser));
    }, [dispatch, idUser]);

    useEffect(() => {
        if (professionnelMatchedSelector.data[0]) {
            setPersonnelMatched(professionnelMatchedSelector.data);
            if (professionnelMatchedSelector.data[0].enabled)
                setCount(professionnelMatchedSelector.data.length);
        }
    }, [professionnelMatchedSelector]);

    const personnelsCalled = (props: DisponibiliteProfessionnel, i: number) => {
        return (
            <Grid item key={i} m={1} lg={6}>
                <WorkerCard {...props} key={i} />
            </Grid>
        );
    };

    const CompanyCalled = (props: DisponibiliteStructure, i: number) => {
        return (
            <Grid item card={6} key={i} lg={9} xs={7}>
                <StructureCard
                    {...props}
                    indexOfDisponibility={i}
                    key={i}
                    profilImageUrl={
                        userSelector.profileImage
                            ? getPictureFromProfilImage(
                                  userSelector.profileImage.contentUrl
                              )
                            : getEmptyAvatarStructure()
                    }
                />
            </Grid>
        );
    };

    return (
        <Stack
            sx={{
                width: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    mt: 4,
                }}
            >
                <Box
                    sx={{
                        width: "fit-content",
                        gridArea: "card",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            ml: 2,
                            mt: 3,
                        }}
                    >
                        {t("dashboard.personal.contacted")}
                        <span
                            style={{
                                backgroundColor: "#3d3d3d",
                                padding: 6,
                                color: "white",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                justifyContent: "center",
                                textAlign: "center",
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {count}
                        </span>
                    </Typography>
                    <Grid
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            mb: 2,
                            ml: 2,
                        }}
                    >
                        {professionnelMatchedSelector.data
                            .filter((item: any, idx: number) => idx < 4)
                            .map(
                                (
                                    item: DisponibiliteProfessionnel,
                                    i: number
                                ) => {
                                    if (item.id.length > 1) {
                                        return personnelsCalled(item, i);
                                    }
                                    return null;
                                }
                            )}
                        {personnelsMatched.length <= 2 && (
                            <EmptyCard
                                message={lang.addPersonnel}
                                component={RouteApp.SEARCH}
                            ></EmptyCard>
                        )}
                    </Grid>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    mt: 1,
                    background: "#DCDCDC",
                    gap: 5,
                    gridTemplateRows: "1",
                    gridTemplateAreas: `
        ". . projects projects projects projects projects projects projects projects projects ."`,
                }}
            >
                <Box
                    sx={{
                        gridArea: "projects",
                        width: "fit-content",
                        justifyContent: "start",
                        alignItems: "start",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{ display: "flex", mt: 3, ml: 2 }}
                    >
                        {t("dashboard.projects")}
                        <span
                            style={{
                                backgroundColor: "#3d3d3d",
                                padding: 6,
                                color: "white",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                justifyContent: "center",
                                textAlign: "center",
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {userSelector.disponibilities.length}
                        </span>
                    </Typography>
                    <Stack
                        sx={{
                            width: "fit-content",
                            flexDirection: "row-reverse",
                            mb: 3,
                            gap: "3rem",
                            ml: 2,
                        }}
                    >
                        {userSelector.disponibilities
                            .slice(currentIndex, currentIndex + 3)
                            .map((item, i) => {
                                return CompanyCalled(item, i);
                            })}
                        {userSelector.disponibilities.length <= 2 && (
                            <EmptyCard
                                message={lang.addProject}
                                component={RouteApp.MY_PROJECTS}
                            ></EmptyCard>
                        )}
                    </Stack>
                    <Stack
                        sx={{
                            position: "absolute",
                            top: "100%",
                            mt: 7,
                            right: 50,
                        }}
                    >
                        {currentIndex > 0 && (
                            <Button
                                onClick={handleBackClick}
                                sx={{ textTransform: "uppercase" }}
                            >
                                <ArrowBackIosNewIcon
                                    sx={{ color: "#4F6F52" }}
                                />
                                Back
                            </Button>
                        )}
                        {currentIndex + 3 <
                            userSelector.disponibilities.length && (
                            <Button
                                onClick={handleNextClick}
                                sx={{ textTransform: "uppercase" }}
                            >
                                Next{" "}
                                <ArrowForwardIosIcon
                                    sx={{ color: "#4F6F52" }}
                                />
                            </Button>
                        )}
                    </Stack>
                </Box>
            </Box>
        </Stack>
    );
};
