import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStateReducer, initialStateReducer } from "../../common/model/IStateReducer";
import { AxiosError } from "axios";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { AvatarResponse, UserStructureResponse, emptyUserStructure } from "../model/StructureModel";
import { ResponseViolations, postAvatar, signUp } from "./signUpAction";




const initialUserStructure: UserStructureResponse = emptyUserStructure

const initialState: IStateReducer<UserStructureResponse> = {
  ...initialStateReducer,
  data: initialUserStructure
};


export const signUpSlice = createSlice({
  name: 'userStructure',
  initialState,
  reducers: {
    // clearUser: (state, action: PayloadAction<UserStructureResponse>) => {
    //   return { 
    //     ...state, 
    //     data: {
    //       ...state.data,
    //       order: action.payload
    //     } 
    //   };
    // },
    // updateUser: (state, action: PayloadAction<UserStructureResponse>) => {
    //   return { 
    //     ...state, 
    //     data: {
    //       ...state.data,
    //       order: action.payload
    //     }
    //   };
    // },
    setPicture: (state, action) => {
      state.data.profileImage = action.payload.data;
    },
    clearPicture: (state) => {
      state.data.profileImage = {contentUrl: '', id: ''};
      state.isExtraField = false;
    },
    setSignUpStructure: (state, action) => {
      state.data = action.payload
    }
    
  },
  extraReducers(builder) {

     builder.addCase(postAvatar.pending, (state) => {
       state.isLoader = true;
       state.isExtraField = true
       state.isSuccess = false;
       state.error = undefined;
       state.requestType = 'POST';
     });
     builder.addCase(postAvatar.fulfilled, (state, action: PayloadAction<AvatarResponse>) => {
       state.data.profileImage = action.payload;
       state.isExtraField = false
       state.isLoader = false;
       state.isSuccess = true;
       state.requestType = 'POST';
     });
     builder.addCase(postAvatar.rejected, (state, action: PayloadAction<any>) => {
       state.isLoader = false;
       state.isExtraField = false
       state.isSuccess = false;
       state.error = action.payload as AxiosError<ResponseViolations>;
       state.requestType = 'POST';
     });

      builder.addCase(signUp.pending, (state) => {
        state.isLoader = true;
        state.isSuccess = false;
        state.error = undefined;
        state.requestType = 'POST';
     });
     builder.addCase(signUp.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseViolations>;
      state.requestType = 'POST';
    });
    // builder.addCase(postAvatar.fulfilled, (state, action) => {
    //   state.data.avatar = action.payload;
    //   state.isLoader = false;
    //   state.isSuccess = true;
    //   state.requestType = 'POST';
    // });
    // builder.addCase(postAvatar.rejected, (state, action: PayloadAction<any>) => {
    //   state.isLoader = false;
    //   state.isSuccess = false;
    //   state.error = action.payload as AxiosError<ResponseMessage>;
    //   state.requestType = 'POST';
    // });
    // // **************** RECUPERATION D'UNE COMMANDE *****************
    // builder.addCase(getOrderById.pending, (state) => {
    //   state.isLoader = true;
    //   state.isSuccess = false;
    //   state.error = undefined;
    //   state.requestType = 'GET';
    // });
    // builder.addCase(getOrderById.fulfilled, (state, action) => {
    //   state.data.order = action.payload;
    //   state.isLoader = false;
    //   state.isSuccess = true;
    //   state.requestType = 'GET';
    // });
    // builder.addCase(getOrderById.rejected, (state, action: PayloadAction<any>) => {
    //   state.isLoader = false;
    //   state.isSuccess = false;
    //   state.error = action.payload as AxiosError<ResponseMessage>;
    //   state.requestType = 'GET';
    // });
    // // **************** MAJ D'UNE COMMANDE *****************
    // builder.addCase(updateOrderById.pending, (state) => {
    //   state.isLoader = true;
    //   state.isSuccess = false;
    //   state.error = undefined;
    //   state.requestType = 'PUT';
    // });
    // builder.addCase(updateOrderById.fulfilled, (state, action) => {
    //   state.data.responseMessage = action.payload;
    //   state.isLoader = false;
    //   state.isSuccess = true;
    //   state.requestType = 'PUT';
    // });
    // builder.addCase(updateOrderById.rejected, (state, action: PayloadAction<any>) => {
    //   state.isLoader = false;
    //   state.isSuccess = false;
    //   state.error = action.payload as AxiosError<ResponseMessage>;
    //   state.requestType = 'PUT';
    // });
  }
})

// Action creators are generated for each case reducer function
export const { clearPicture, setSignUpStructure, setPicture} = signUpSlice.actions

export default signUpSlice