import React, { useState } from "react";
import { api } from "../common/service/apiService";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

export function UploadDocument() {
    const [isLoading, setIsLoading] = useState(false);
    const [documentName, setDocumentName] = useState("");
    const [documentUri, setDocumentUri] = useState("");
    const [documentType, setDocumentType] = useState("");
    const [description, setDescription] = useState("");
    const { t } = useTranslation();
    const openDocumentPicker = async () => {
        try {
            const fileInput = document.createElement("input");
            fileInput.type = "file";
            fileInput.accept = "application/pdf, image/png, image/jpeg";
            fileInput.click();

            fileInput.onchange = (e: any) => {
                const file = e?.target?.files[0];
                if (file) {
                    setDocumentName(file.name);
                    setDocumentUri(file);
                    setDocumentType(file.type);
                }
            };
        } catch (error) {
            console.error("Error picking document:", error);
        }
    };

    const uploadDocument = async () => {
        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append("file", documentUri);
            formData.append("name", documentName);
            formData.append("description", "Document uploaded from web app");
            console.log("formData", formData);

            const response = await api(
                "/api/documents/send",
                "POST",
                formData,
                {
                    "Content-Type": "multipart/form-data",
                }
            );

            console.log("Document uploaded:", response);

            setIsLoading(false);
            alert("Document uploaded successfully!");
        } catch (error) {
            console.error("Error uploading document:", error);
            setIsLoading(false);
            alert("Failed to upload document. Please try again later.");
        }
    };

    return (
        <div>
            <Button onClick={openDocumentPicker}>
                {t("button.upload.document")}
            </Button>
            <input
                style={{
                    margin: 10,
                    padding: 10,
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                }}
                type="text"
                placeholder="Document Name"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
            />
            <input
                style={{
                    margin: 10,
                    padding: 10,
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                }}
                type="text"
                placeholder={t("profile.document.description")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <Button onClick={uploadDocument} disabled={!documentUri}>
                    {t("button.send.document")}
                </Button>
            )}
            {documentUri && (
                <div>
                    <strong>Preview:</strong>
                    {documentType.startsWith("image") ? (
                        <img
                            src={documentUri}
                            alt={documentName}
                            style={{
                                maxWidth: "100%",
                                maxHeight: 300,
                                marginTop: 10,
                            }}
                        />
                    ) : (
                        <embed
                            src={documentUri}
                            type="application/pdf"
                            style={{
                                width: "100%",
                                height: 300,
                                marginTop: 10,
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default UploadDocument;

const Button = styled("button")({
    padding: "0.5rem",
    fontWeight: 500,
    borderRadius: "5px",
});
