import { Alert, AlertColor, Snackbar, SnackbarOrigin } from '@mui/material';
import React, { FunctionComponent } from 'react';
import './CustomSnackbar.css';

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  severity: AlertColor | undefined;
  position?: SnackbarOrigin;
  handleCloseSnackbar: (event?: React.SyntheticEvent | Event, reason?: string) => void;
  autoHideDuration?: number;
}

const CustomSnackbar: FunctionComponent<CustomSnackbarProps> = (props: CustomSnackbarProps) => {
  return (
    <Snackbar
      className="snackbar"
      open={props.open}
      autoHideDuration={props.autoHideDuration ? props.autoHideDuration : 6000}
      onClose={props.handleCloseSnackbar}
      anchorOrigin={props.position ? props.position : { vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={props.handleCloseSnackbar} severity={props.severity} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
