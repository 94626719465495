import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { RouteAPI, api } from "../../common/service/apiService";
import { storageService } from "../../common/service/storageService";


interface ModifyProfil {
  societe: string,
  siret: string,
  email: string,
  plainPassword?: string,
  telephone: string,
  profileImage?: string,
  description: string
}

export const postUpdateProfil = createAsyncThunk<any, ModifyProfil>(
  "profil/postUpdate",
  async (data, thunkAPI) => {
    try {
      const response = await api(
        RouteAPI.STRUCTURES + "/" + storageService.getIdWithToken(),
        "PATCH",
        data
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
  }
);

// export const getAllDisponibilities = (): ThunkAction<void, RootState, unknown, AnyAction> => {
//   return async(dispatch, getState) => {
//     dispatch(getDisponibilities());
//   }
// }
