export const PolitiqueConfidentialite = `Dernière mise à jour : 23/11/2023

Bienvenue sur Smart Santé, une application mobile conçue pour faciliter la mise en relation entre les structures de santé et le personnel soignant. Chez Smart Santé, nous sommes engagés à protéger la vie privée de nos utilisateurs. Cette politique de confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations personnelles. En utilisant l'application Smart Santé, vous acceptez les termes décrits dans cette politique.

1. Informations Collectées

1.1 Informations Personnelles : Nous collectons des informations personnelles telles que votre nom, prénom, adresse e-mail, numéro de téléphone, et autres informations similaires lors de la création de votre compte Smart Santé.

1.2 Informations Professionnelles : Dans le cadre de l'utilisation de l'application en tant que professionnel de la santé, nous collectons des informations liées à votre profession, qualifications, et toute autre information pertinente pour faciliter la mise en relation avec des structures de santé.

1.3 Données d'Utilisation : Nous collectons des informations sur la manière dont vous utilisez l'application, y compris les fonctionnalités que vous utilisez, le temps que vous passez sur l'application, et d'autres statistiques d'utilisation.

2. Utilisation des Informations

2.1 Mise en Relation : Les informations collectées sont utilisées pour faciliter la mise en relation entre les structures de santé et le personnel soignant, conformément à vos préférences et qualifications.

2.2 Amélioration de l'Expérience Utilisateur : Nous utilisons les données d'utilisation pour améliorer constamment notre application, identifier les fonctionnalités populaires et résoudre tout problème technique.

2.3 Communication : Nous pouvons utiliser vos informations pour vous envoyer des communications liées à l'application, telles que des mises à jour, des alertes et des informations pertinentes.

3. Partage d'Informations

3.1 Avec les Structures de Santé : Vos informations professionnelles seront partagées avec les structures de santé pour faciliter la mise en relation.

3.2 Partenaires de Services : Dans certains cas, nous pouvons partager vos informations avec des partenaires de services tiers qui nous aident à fournir des fonctionnalités spécifiques de l'application.

3.3 Conformité Juridique : Nous pouvons divulguer vos informations si requis par la loi ou dans le cadre d'une procédure judiciaire.

4. Sécurité des Informations

Nous mettons en place des mesures de sécurité appropriées pour protéger vos informations contre tout accès non autorisé, altération, divulgation ou destruction.

5. Consentement

En utilisant l'application Smart Santé, vous consentez à la collecte et à l'utilisation de vos informations conformément à cette politique de confidentialité.

6. Modifications de la Politique de Confidentialité

Cette politique de confidentialité peut être mise à jour de temps à autre. Les modifications seront publiées sur l'application et prendront effet dès leur publication.

Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante : contact@smartsanteconseil.fr.

Nous vous remercions de faire confiance à Smart Santé pour faciliter la connexion entre les structures de santé et le personnel soignant.

L'équipe Smart Santé
`;
