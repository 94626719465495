import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface PasswordInputProps {
    label?: string;
}

export default function PasswordInput(props: PasswordInputProps) {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <TextField
            required
            fullWidth
            name="password"
            label={props.label ? props.label : t("auth.password")}
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}
