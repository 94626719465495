import { useTranslation } from "react-i18next";

export const GetTranslatedProfessions = () => {
    const { t } = useTranslation();

    const PROFESSION = [
        "Radiologue",
        "Secrétaire médicale",
        "Educateur moniteur spécialisée",
        "Assistante sociale",
        "Accompagnant éducatif et social",
        "Educateur sportif",
        "Agent de stérilisation",
        "Auxiliaire de vie",
        "Biologiste",
        "Médecin",
        "Chirurgien-dentiste",
        "Orthodontistes",
        "Sage-femmes",
        "Pharmacien",
        "Préparateur en pharmacie",
        "Aides-soignants",
        "Auxiliaires de puériculture",
        "Ambulanciers",
        "Infirmier",
        "Masseur-kinésithérapeute",
        "Podologue",
        "Ergothérapeute",
        "Psychomotricien",
        "Manipulateur d'électroradiologie médicale",
        "Technicien de laboratoire médical",
        "Audioprothésiste",
        "Diététicien",
    ];

    return PROFESSION.map((profession) => t(`professions.${profession}`));
};
