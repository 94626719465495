export const RouteApp = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  DASHBOARD: "/dashboard",
  MY_ACCOUNT: "/profil",
  SEARCH: "/search",
  MY_PROJECTS: "/projects",
  MY_PROJECTS_DETAILS: "/projects/details",
  PROFESSIONNELS: "/personnels",
  FORGOT_PASSWORD: "/reset",
  RESET_PASSWORD: "/reset-password",
  LEGACY: "/informations-legales",
};
