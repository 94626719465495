import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
    Alert,
    Box,
    Button,
    Divider,
    Paper,
    Typography,
    styled,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import fleche from "../assets/fleche.png";
import { Modal } from "../common/components/Modal";
import { RouteApp } from "../common/model/RouteApp";
import { useAppDispatch } from "../common/redux/reduxHooks";
import { DisponibiliteStructure } from "./model/StructureModel";
import { deleteProject } from "./redux/projectAction";
import { ItemNight } from "../cards/WorkerCard";
import { useTranslation } from "react-i18next";

export const ProjectDetailsComponent = (props: DisponibiliteStructure) => {
    const [isDisplayPopupDeleteProject, setisDisplayPopupDeleteProject] =
        React.useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const deleteDispo = () => {
        setisDisplayPopupDeleteProject(false);
        dispatch(deleteProject(props.id));
        navigate(RouteApp.MY_PROJECTS);
    };

    function capitalizeFirstLetter(string: string) {
        if (string) {
            return string[0].toUpperCase() + string.slice(1);
        }
    }

    return (
        <>
            <Paper
                elevation={2}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    p: "15px",
                    mt: 5,
                    borderRadius: "10px",
                    justifyContent: "space-evenly",
                }}
            >
                <Typography
                    sx={{
                        border: "1px solid #B95757",
                        p: 1.2,

                        borderRadius: "5px",
                    }}
                >
                    {props.profession}
                </Typography>
                <Divider orientation="vertical" flexItem color="gray" />
                <Typography
                    sx={{
                        border: "1px solid #B95757",
                        p: 1.2,
                        m: 1,
                        borderRadius: "5px",
                    }}
                >
                    {props.ville}
                </Typography>
                <Divider orientation="vertical" flexItem color="gray" />
                <Box
                    sx={{
                        display: "inherit",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="body2"
                        sx={{
                            backgroundColor: "#696969",
                            color: "white",
                            p: 1.3,
                            ml: 1,
                            mt: 0.6,
                            borderRadius: "5px",
                            mr: 0,
                            fontSize: "2ex",
                        }}
                    >
                        {new Date(props.dateDemarrage).toLocaleDateString(
                            "fr-FR"
                        )}
                    </Typography>
                    <div>
                        <img
                            src={fleche}
                            alt="Arrow"
                            style={{
                                width: "32px",
                                height: "32px",
                                marginTop: "0.25rem",
                                zIndex: "10",
                                margin: 0.2,
                            }}
                        ></img>
                    </div>
                    <Typography
                        gutterBottom
                        variant="body2"
                        sx={{
                            backgroundColor: "#696969",
                            color: "white",
                            p: 1.3,
                            mt: 0.6,
                            mr: 1,
                            borderRadius: "5px",
                            fontSize: "2ex",
                        }}
                    >
                        {props.dateFin
                            ? new Date(props.dateFin).toLocaleDateString(
                                  "fr-FR"
                              )
                            : t("select.indifferent")}
                    </Typography>
                </Box>
                <Divider orientation="vertical" flexItem color="gray" />
                <Box
                    sx={{
                        display: "inherit",
                        textAlign: "center",
                    }}
                >
                    {props.selection &&
                        props.selection.map((e) => {
                            return e !== t("select.night") ? (
                                <Item>{capitalizeFirstLetter(e)}</Item>
                            ) : (
                                <ItemNight>
                                    {capitalizeFirstLetter(e)}
                                </ItemNight>
                            );
                        })}
                </Box>
                <Button
                    color="error"
                    sx={{ width: "fit-content", padding: 1.1 }}
                    onClick={() => setisDisplayPopupDeleteProject(true)}
                    variant="contained"
                    size="medium"
                    endIcon={<HighlightOffIcon />}
                >
                    {t("button.delete.project")}
                </Button>
            </Paper>
            <Modal
                open={isDisplayPopupDeleteProject}
                message={" "}
                onCancel={() => setisDisplayPopupDeleteProject(false)}
                titleButtonCancel={t("button.modal.cancel")}
                title={t("project.delete.confirmation")}
                onClose={() => setisDisplayPopupDeleteProject(false)}
                onValidation={() => deleteDispo()}
                titleButtonValidation={t("button.modal.valid")}
            ></Modal>
            {props.enabled ? null : (
                <Alert
                    color="error"
                    sx={{
                        width: "30%",
                        mr: "auto",
                        ml: "auto",
                        mt: 5,
                    }}
                >
                    {t("project.validation")}
                </Alert>
            )}
        </>
    );
};

const Item = styled(Typography)(() => ({
    background:
        "linear-gradient(to right top, #4f6f52, #668468, #7e9a7e, #96b195, #afc8ad)",
    margin: "0.25rem",
    padding: 10,
    borderRadius: "2px",
    color: "white",
    fontWeight: "bold",
    justifyContent: "center",
    display: "flex",
    fontSize: "16px",
    width: "90px",
    wordWrap: "break-word",
}));
