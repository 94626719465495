import {
    Alert,
    Box,
    Button,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { RouteApp } from "../common/model/RouteApp";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { forgotPassword } from "./redux/authAction";
import { Modal } from "../common/components/Modal";
import { RootState } from "../common/redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ForgotPage: FunctionComponent = () => {
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const { t } = useTranslation();
    const authSelector: any = useAppSelector((state: RootState) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get("email")?.toString();

        if (!email) {
            setErrorMessage(t("forgot.password.error.empty"));
            return;
        }

        if (data.get("email")?.toString().includes("@")) {
            dispatch(forgotPassword(data.get("email") as string)).then(
                (res: any) => {
                    if (authSelector.isSuccessPasswordRequest) {
                        setIsOpenModal(true);
                    } else setErrorMessage(t("forgot.password.error"));
                }
            );
        } else setErrorMessage(t("forgot.password.error.email"));
    };

    useEffect(() => {
        if (authSelector.isSuccessPasswordRequest) {
            setIsOpenModal(true);
            setErrorMessage(null);
        } else if (authSelector.isErrorPasswordRequest) {
            setErrorMessage(t("forgot.password.error"));
            setIsOpenModal(false);
        }
    }, [
        authSelector.isErrorPasswordRequest,
        authSelector.isSuccessPasswordRequest,
        t,
    ]);

    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{ justifyContent: "center" }}
        >
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    {t("forgot.password.title")}
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t("auth.email")}
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="error"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {t("forgot.password.reset")}
                    </Button>
                    {errorMessage ? (
                        <Alert severity="error" className="errorMessage">
                            {errorMessage}
                        </Alert>
                    ) : null}
                    <Grid container>
                        <Grid item>
                            <Link href={RouteApp.LOGIN} variant="body2">
                                {t("forgot.password.login")}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Modal
                open={isOpenModal}
                message={t("forgot.password.modal")}
                title={t("forgot.password.title.modal")}
                onClose={() => setIsOpenModal(false)}
                onValidation={() => {
                    setIsOpenModal(false);
                    navigate(RouteApp.LOGIN);
                }}
                titleButtonValidation={t("forgot.password.button.modal")}
            ></Modal>
        </Container>
    );
};
