import { AxiosError, Method } from "axios";
import { ResponseMessage } from "./ResponseMessage";
import { ResponseViolations } from "../../structures/redux/signUpAction";

export interface IStateReducer<T> {
  isLoader: boolean;
  isSuccess?: boolean;
  isExtraField?: boolean;
  isCreateProject?: boolean;
  requestType?: Method;
  data: T;
  error?: AxiosError<ResponseViolations | ResponseMessage>;
  isErrorPasswordRequest?: AxiosError<ResponseViolations | ResponseMessage>
  isSuccessPasswordConfirm?: boolean
  isErrorPasswordConfirm?: boolean
  isSuccessPasswordRequest?: boolean
}

/**
 * Initial State Reducer
 */
 export const initialStateReducer = {
  isLoader: false,
  isSuccess: false,
  error: undefined,
};