export const MentionLegal = `MENTIONS LÉGALES DE L'APPLICATION MOBILE "Smart SANTÉ"

Éditeur de l'application :

Smart Santé
57 Rue Paradis 13006 Marseille
04 91 11 00 30
contact@smartsanteconseil.fr
89456141400026



Données personnelles :

L'application mobile "Smart Santé" collecte et traite des données personnelles conformément à la réglementation en vigueur. Pour plus d'informations sur la collecte, le traitement et la protection de vos données personnelles, veuillez consulter notre politique de confidentialité .

Cookies :

L'application peut utiliser des cookies pour améliorer l'expérience utilisateur. En continuant à utiliser l'application, vous consentez à l'utilisation de ces cookies. Pour plus d'informations sur l'utilisation des cookies, veuillez consulter notre politique relative aux cookies.

Propriété intellectuelle :

L'ensemble des contenus de l'application (textes, images, vidéos, logos, etc.) sont la propriété exclusive de Smart Santé ou font l'objet d'une autorisation d'utilisation. Toute reproduction ou utilisation non autorisée est strictement interdite.

Responsabilité :

Smart Santé décline toute responsabilité quant à l'utilisation de l'application et aux conséquences directes ou indirectes résultant de cette utilisation.

Contact :

Pour toute question ou réclamation, vous pouvez nous contacter à l'adresse suivante :
57 Rue Paradis 13006 Marseille
04 91 11 00 30
contact@smartsanteconseil.fr

Date de dernière mise à jour :

23/11/2023`;
