export interface DisponibiliteProfessionnel {
  account: UserProfessionnelResponse;
  departements: string[];
  id: string;
  selection: string[];
  description: string;
  enabled: boolean;
  dateDemarrage: Date;
  dateFin: Date;
  isInterested: { status: string } | null;
  isMock?: boolean;
  profilImageUrl?: string;
  indexOfDisponibility?: number;
  fulfilled?: boolean;
}

export interface UserProfessionnelResponse {
  nom: string;
  prenom: string;
  profession: string[];
  disponibilities: string[];
  id: string;
  email: string;
  roles: string[];
  enabled: boolean;
  telephone: string;
  profileImage: { contentUrl: string; id: string };
  description: string;
  indexOfDisponibility?: number;
  selection?: string[];
}

export const emptyUserProfessionnel: UserProfessionnelResponse = {
  nom: "",
  prenom: "",
  profession: [""],
  disponibilities: [],
  id: "",
  email: "",
  roles: [],
  enabled: false,
  telephone: "",
  profileImage: { contentUrl: "", id: "" },
  description: "",
  selection: ["", ""],
};

// {
//   departements: [],
//   id: '',
//   mois: [],
//   selection: [],
//   description: '',
//   type: '',
//   enabled: false,
//   dateDemarrage: new Date(),
//   dateFin: new Date(),
// }
