import { Box, Grid, Pagination, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState, useCallback, Key } from "react";
import { SearchComponent } from "./SearchComponent";
import { WorkerCard } from "../cards/WorkerCard";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { DisponibiliteProfessionnel } from "./model/Disponibilite";
import { getDisponibilities } from "./redux/searchAction";
import "./SearchPage.css";
import { useTranslation } from "react-i18next";

export const SearchPage = () => {
    const [page, setPage] = useState<number>(1);
    const itemsPerPage = 12;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const disponibilities = useAppSelector(
        (state: RootState) => state.search.data
    );

    useEffect(() => {
        dispatch(getDisponibilities(null));
    }, [dispatch]);

    const handleChangePage = useCallback((e: any, p: number) => {
        setPage(p);
    }, []);

    const paginatedDisponibilities = useMemo(() => {
        if (!disponibilities) return [];
        return disponibilities.slice(
            (page - 1) * itemsPerPage,
            page * itemsPerPage
        );
    }, [disponibilities, page, itemsPerPage]);

    const count = useMemo(() => {
        return disponibilities
            ? Math.ceil(disponibilities.length / itemsPerPage)
            : 0;
    }, [disponibilities, itemsPerPage]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Stack sx={{ mt: 5 }}>
                <SearchComponent />
            </Stack>
            <Box sx={{ mr: 2, width: "100%" }}>
                <Pagination
                    sx={{ mt: 2, mb: 2 }}
                    count={count}
                    defaultPage={1}
                    size="medium"
                    page={page}
                    variant="outlined"
                    onChange={handleChangePage}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                >
                    {paginatedDisponibilities.length > 0 ? (
                        paginatedDisponibilities.map(
                            (
                                item: JSX.IntrinsicAttributes &
                                    DisponibiliteProfessionnel,
                                i: Key | null | undefined
                            ) =>
                                item.account ? (
                                    <Grid item key={i} m={1} lg={5}>
                                        <WorkerCard key={i} {...item} />
                                    </Grid>
                                ) : null
                        )
                    ) : (
                        <Typography sx={{ mt: "50px", mr: "auto", ml: "auto" }}>
                            {t("search.no.results")}
                        </Typography>
                    )}
                </Box>
                {disponibilities && disponibilities.length > itemsPerPage && (
                    <Pagination
                        count={count}
                        sx={{ justifyContent: "center", mt: 2, mb: 2 }}
                        defaultPage={1}
                        size="large"
                        page={page}
                        variant="outlined"
                        onChange={handleChangePage}
                    />
                )}
            </Box>
        </Box>
    );
};
