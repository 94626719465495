import { Dialog, DialogTitle, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import styled from "@emotion/styled";
import ShopOutlinedIcon from "@mui/icons-material/ShopOutlined";
import AppleIcon from "@mui/icons-material/Apple";

interface SmallDevicesProps {
    isOpen: boolean;
}

export const SmallDevices = ({ isOpen }: SmallDevicesProps) => {
    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <StyledTypography>
                    Pour une expérience optimale, téléchargez et utilisez notre
                    application mobile depuis les stores. Profitez de
                    fonctionnalités exclusives et d'une navigation plus fluide.
                </StyledTypography>
            </DialogTitle>
            <StyledBox component="div">
                <StyledLink
                    href="https://play.google.com/store/apps/details?id=fr.smartsanteconseil.app&hl=ro&gl=US"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <ShopOutlinedIcon />
                    Google Play
                </StyledLink>
                <StyledLink
                    href="https://apps.apple.com/ro/app/smart-sant%C3%A9/id6474447556"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <AppleIcon />
                    Apple Store
                </StyledLink>
            </StyledBox>
        </Dialog>
    );
};

export default SmallDevices;

const StyledTypography = styled(Typography)({
    fontSize: "20px",
    textAlign: "center",
});

const StyledBox = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "center",
    gap: "2rem",
    paddingBottom: "0.5rem",
});

const StyledLink = styled(Link)({
    width: "auto",
    border: "2px #3d3d3d solid",
    borderRadius: "5px",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    padding: "0.4rem",
    gap: "0.25rem",

    "&:active": {
        transform: "translateY(1px)",
    },
});
