import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { initialStateReducer, IStateReducer } from "../../common/model/IStateReducer";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { getInterestProfessionnels } from "./professionnelAction";
import { DisponibiliteProfessionnel, emptyUserProfessionnel } from "../../search/model/Disponibilite";
import { defaultProfessionnelProps } from "../../cards/WorkerCard";


const initialUsersState: IStateReducer<DisponibiliteProfessionnel[]> = {
  ...initialStateReducer,
  data: [defaultProfessionnelProps]
}
export const professionnelSlice = createSlice({
  name: 'professionnel',
  initialState: initialUsersState,
  reducers: {

  },
  extraReducers(builder) {
    builder.addCase(getInterestProfessionnels.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getInterestProfessionnels.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getInterestProfessionnels.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
  },
})

// Action creators are generated for each case reducer function


export default professionnelSlice