import axios, { AxiosResponse, Method } from 'axios';
import axiosInstance from '../api/globalInterceptorSetup';

/**
 * Route API
*/
export const RouteAPI = {
  GET_MEDIA_OBJECTS_ID: '/api/media_objects/',
  LOGIN: '/login_check',
  LOGIN_API: '/api/login_check',
  STRUCTURES: '/api/structures',
  DELETE_USER:'/api/account',
  UPLOAD_DOCUMENT: "/api/documents/send",
  REFRESH_TOKEN: '/api/token/refresh',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/reset-password/reset/',
  USER: '/user',
  INVALIDATE_TOKEN: '/token/invalidate',
  DASHBOARD: '/dashboard',
  PROFIL: '/user',
  SEARCH: '/search',
  PROJECT: '/projects',
  PERSONNELS: '/personnels',
  SIGN_UP: '/api/structures',
  UPLOAD_IMAGE: '/api/media_objects',
  DISPONIBILITE_STRUCTURE: '/api/disponibilite_structures',
  DISPONIBILITE_PROFESSIONNELS: '/api/disponibilite_professionnels',
  GET_DEPARTEMENT_FROM_GOVERNMENT: 'https://geo.api.gouv.fr/communes?fields=nom&format=json',
  FORGOT_PASSWORD_REQUEST: '/api/user/reset/request',
  FORGOT_PASSWORD_CONFIRM: '/api/user/reset/confirm'
}

/**
 * Consume API
 *
 * @param url is the server route URL that will be used for the request
 * @param method is the request method to be used when making the request
 * @param data is the data to be sent as the request body. ex {id: '1234'}:
 * @param params are the URL parameters to be sent with the request. ex {firstname: 'Fred'}:
 *
 * @returns Promise<R>
 */
 export function api<T = any, R = AxiosResponse<T>>(
  url: string,
  method: Method,
  data: any = {},
  params: any = {}
): Promise<R> {
  return axiosInstance.request({
      url: url,
      method: method,
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 30000,
      params: params,
      data: data,
  });
}

// export function uploadAvatar<T = any, R = AxiosResponse<T>>(
//   file: File
// ): Promise<R> {
//   return axios.post('http://localhost:8080' + RouteAPI.UPLOAD_IMAGE, {file: file}, {
//            headers: {
//              'Content-Type': 'multipart/form-data'
//            }
//          }).then((res) => res.data)
// }

