import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { RouteAPI, api } from "../../common/service/apiService";


export const getInterestProfessionnels = createAsyncThunk<any, string>(
  "professionnel/getInterest",
  async (id, thunkAPI) => {
    try {
      const response = await api(
        RouteAPI.DISPONIBILITE_PROFESSIONNELS + "?interested.Structure.id=" + id,
        "GET"
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
  }
);

// export const getAllDisponibilities = (): ThunkAction<void, RootState, unknown, AnyAction> => {
//   return async(dispatch, getState) => {
//     dispatch(getDisponibilities());
//   }
// }
