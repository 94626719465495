

export const getPictureFromProfilImage = (url: string) => {
  return process.env.REACT_APP_API_URL + url
}

export const getEmptyAvatarStructure = () => {
  const emptyAvatar = require('../assets/emptyAvatar.png'); 
  return emptyAvatar
}

export const getEmptyAvatarProfessionnel = () => {
  const emptyAvatar = require('../assets/emptyProfessionnel.png'); 
  return emptyAvatar
}