import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Container,
    Link,
    TextField,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../common/components/PasswordInput";
import { ResponseMessage } from "../common/model/ResponseMessage";
import { RouteApp } from "../common/model/RouteApp";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { storageService } from "../common/service/storageService";
import { login } from "../login/redux/authAction";
import { RouteUtils } from "../utils/RouteUtils";
import { useTranslation } from "react-i18next";

const LoginPage: FunctionComponent = () => {
    const [errorMessageRefreshToken, setErrorMessageRefreshToken] = useState<
        string | null
    >(null);
    const [snackBarMessage, setSnackBarMessage] = React.useState<
        ResponseMessage | any
    >(undefined);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const tokens = useAppSelector((state: RootState) => state.auth);
    const userSelector = useAppSelector((state: RootState) => state.user);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        dispatch(
            login({
                email: data.get("email") as string,
                password: data.get("password") as string,
            })
        );
    };

    useEffect(() => {
        if (tokens.isSuccess && tokens.data.token.length > 0) {
            if (tokens.data.roles.includes("ROLE_PROFESSIONNEL")) {
            } else navigate(RouteUtils.DASHBOARD);
        }
        if (!tokens.isSuccess) {
            setSnackBarMessage(tokens.error);
        }
    }, [navigate, tokens]);

    useEffect(() => {
        if (userSelector.isSuccess && userSelector.data.id.length > 1) {
            navigate(RouteUtils.DASHBOARD);
        }
    }, [navigate, userSelector]);

    useEffect(() => {
        if (storageService.getErrorMessageRefreshToken()) {
            setErrorMessageRefreshToken(
                storageService.getErrorMessageRefreshToken()
            );
            storageService.removeErrorMessageRefreshToken();
        }
    }, [errorMessageRefreshToken, tokens]);

    function Copyright(props: any) {
        return (
            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                {...props}
            >
                {"Copyright © "}
                <Link color="inherit">Smart Santé</Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        );
    }

    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{ justifyContent: "center" }}
        >
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "#3d3d3d" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("authentication")}
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t("auth.email")}
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <PasswordInput />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="error"
                        sx={{ mt: 3, mb: 2 }}
                        endIcon={
                            tokens.isLoader ? (
                                <ColorRing
                                    width="30px"
                                    height="30px"
                                    colors={[
                                        "#A93F58",
                                        "#FE807F",
                                        "white",
                                        "#FE807F",
                                        "#A93F58",
                                    ]}
                                />
                            ) : null
                        }
                    >
                        {t("auth.login.button")}
                    </Button>

                    {snackBarMessage ? (
                        <Alert severity="error" className="errorMessage">
                            {snackBarMessage
                                ? snackBarMessage.message
                                : t("auth.error.login")}
                            {}
                        </Alert>
                    ) : null}
                    <Grid container>
                        <Grid item xs>
                            <Link
                                href={RouteApp.FORGOT_PASSWORD}
                                variant="body2"
                            >
                                {t("auth.forgot.password")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href={RouteApp.SIGNUP} variant="body2">
                                {t("auth.sign.up")}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
};

export default LoginPage;
