import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import MainRoutes from "./common/components/MainRoutes";
import SmallDevices from "./common/components/SmallDevices";
import CitiesAvailable from "./utils/Cities.json";
import { RouteApp } from "./common/model/RouteApp";
import useIpLanguageDetection from "./common/api/useIpLanguageDetection";

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        mobile: true; // adds the `mobile` breakpoint
        tablet: true;
        laptop: true;
        card: true;
    }
}

const defaultTheme = createTheme();
const theme = createTheme({
    palette: {
        primary: {
            main: "#3d3d3d",
        },
        secondary: {
            main: "#FE807F",
        },
    },
    typography: {
        fontFamily: "Montserrat",
        button: {
            textTransform: "none",
        },
        h4: {
            color: "#3d3d3d",
        },
    },
    breakpoints: {
        values: {
            ...defaultTheme.breakpoints.values,
            mobile: 0,
            tablet: 0,
            laptop: 0,
            card: 1800,
        },
    },
});

export interface CitiesFromGovernment {
    nom: string;
    code: string;
}

export let Cities: CitiesFromGovernment[] = CitiesAvailable;

// main: 'linear-gradient(to right , #A93F58, #FE807F)'

function App() {
    useIpLanguageDetection();

    //  useEffect(() => {
    //    if(Cities.length < 10){
    //      dispatch(getCity(null)).then((res: any) => {
    //        if(Cities.length < 10){
    //        res.payload.forEach((city: CitiesFromGovernment) => {
    //          Cities.push(city)
    //        });}
    //     });
    //    }
    //  }, [])
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);
    const isForLegacy = window.location.href.includes(RouteApp.LEGACY);
    const isMobile = width <= 1230 && !isForLegacy;

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                {!isMobile && (
                    <>
                        <MainRoutes />
                    </>
                )}
                <SmallDevices isOpen={isMobile} />
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
