import {
    Alert,
    Box,
    Button,
    CssBaseline,
    Grid,
    Paper,
    TextField,
    useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { UserStructureResponse } from "../structures/model/StructureModel";
import {
    getEmptyAvatarStructure,
    getPictureFromProfilImage,
} from "../utils/FormatUtils";
import { postUpdateProfil } from "./redux/profilAction";
import { logout } from "../login/redux/authAction";
import { Modal } from "../common/components/Modal";
import { getUser,deleteUser } from "../common/redux/userAction";
import { storageService } from "../common/service/storageService";
import UploadDocument from "./UploadDocument";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";

interface ChangeProfil {
    societe: string;
    siret: string;
    telephone: string;
    email: string;
    description: string;
}

export const ProfilPage = () => {
    const userSelector: UserStructureResponse = useAppSelector(
        (state: RootState) => state.user.data
    );
    const profilSelector: any = useAppSelector(
        (state: RootState) => state.profil
    );
    const [account, setAccount] = useState<UserStructureResponse>(userSelector);
    const [isOpenConfirmModal, setIsOpenConfirmModal] =
        useState<boolean>(false);
        const [isOpenConfirmModalDelete, setIsOpenConfirmModalDelete] =
        useState<boolean>(false);
    const [isOpenErrormModal, setIsOpenErrorModal] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { t } = useTranslation();
    const theme = useTheme();
    useEffect(() => {
        setAccount(userSelector);
    }, [userSelector, account]);

    useEffect(() => {
        const idUser = storageService.getIdWithToken();
        if (idUser) dispatch(getUser(idUser));
    }, [dispatch]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!profilSelector.error) {
            const data = new FormData(event.currentTarget);
            dispatch(
                postUpdateProfil({
                    description: data.get("description") as string,
                    email: data.get("email") as string,
                    siret: data.get("siret") as string,
                    societe: data.get("societe") as string,
                    telephone: data.get("phone") as string,
                })
            ).then((res: any) => {
                if (!res.error) {
                    setIsOpenConfirmModal(true);
                    setIsOpenErrorModal(false);
                } else {
                    setIsOpenConfirmModal(false);
                    setIsOpenErrorModal(true);
                }
            });
        }
    };

    const handleDeleteAccount = () => {
        
        dispatch(deleteUser()).then(() => {
            setIsOpenConfirmModalDelete(false);
            dispatch(logout())
            navigate('/login')
        });
       
    }

    return (
        <Box sx={{ ml: 10 }}>
            <Paper
                sx={{
                    maxWidth: theme.breakpoints.values.md,
                    mr: "auto",
                    ml: "auto",
                    mt: 5,
                    p: 5,
                }}
                elevation={12}
            >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: "300px",
                            maxHeight: "300px",
                            mt: "0px",
                        }}
                    >
                        {account.profileImage && (
                            <img
                                src={getPictureFromProfilImage(
                                    account.profileImage.contentUrl
                                )}
                                style={{
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                }}
                                alt="Profil"
                            />
                        )}
                        {!account.profileImage && (
                            <img
                                src={getEmptyAvatarStructure()}
                                style={{
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                }}
                                alt="Profil"
                            />
                        )}
                    </Box>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{ mt: 3 }}
                    >
                        <Box
                            sx={{
                                mb: 4,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="societe"
                                        label={t("sign.up.company")}
                                        name="societe"
                                        autoComplete="organization"
                                        defaultValue={account.societe}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="siret"
                                        label={t("sign.up.number")}
                                        name="siret"
                                        autoComplete="tel-national"
                                        defaultValue={account.siret}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="phone"
                                        label={t("sign.up.phone")}
                                        name="phone"
                                        autoComplete="tel-national"
                                        defaultValue={account.telephone}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label={t("auth.email")}
                                        name="email"
                                        autoComplete="email"
                                        defaultValue={account.email}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        placeholder="Description"
                                        multiline
                                        label="Description"
                                        fullWidth
                                        defaultValue={account.description}
                                        minRows={2}
                                        id="description"
                                        name="description"
                                        maxRows={4}
                                    />
                                </Grid>
                            </Grid>
                            <UploadDocument />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    width: "50%",
                                    padding: "0.5rem",
                                    borderRadius: "10px",
                                    background:
                                        "linear-gradient(to right bottom, #618264, #709272, #80a280, #90b38e, #a0c49d)",
                                    textTransform: "uppercase",
                                }}
                                color="success"
                            >
                                {t("button.update.profile")}
                            </Button>
                            <Button
                                onClick={() => setIsOpenConfirmModalDelete(true)}
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    width: "25%",
                                    padding: "0.5rem",
                                    borderRadius: "10px",
                                    background:
                                        "linear-gradient(to right bottom, #618264, #709272, #80a280, #90b38e, #a0c49d)",
                                    textTransform: "uppercase",
                                }}
                                color="warning"
                            >
                                Supprimer votre profil
                            </Button>
                        </Box>
                    </Box>

                    {profilSelector.error && (
                        <Alert severity="error" className="errorMessage">
                            {profilSelector.error?.response?.data.detail}
                        </Alert>
                    )}
                    <Modal
                    open={isOpenConfirmModalDelete}
                    onValidation={() => handleDeleteAccount()}
                    onClose={() => setIsOpenConfirmModalDelete(false)}
                    title={"Suppression du commpte"}
                    message={"Etes vous sur de supprimer votre compte ?"}
                    titleButtonValidation={"Supprimer"}
                    />
                    <Modal
                        open={isOpenConfirmModal}
                        onValidation={() => setIsOpenConfirmModal(false)}
                        onClose={() => setIsOpenConfirmModal(false)}
                        title={t("modal.update.profile.title")}
                        message={t("modal.update.profile.description")}
                        titleButtonValidation={t("button.modal.valid")}
                    />
                </Box>
            </Paper>
        </Box>
    );
};
