import {
    Autocomplete,
    Box,
    Button,
    Grid,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    styled,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/fr";
import * as React from "react";
import { Cities, CitiesFromGovernment } from "../App";
import LoupeIcon from "../assets/loupeIcon.svg";
import { useAppDispatch } from "../common/redux/reduxHooks";
import { GetTranslatedProfessions } from "../utils/AvailableJobsUtils";
import "./SearchComponent.css";
import moment from "moment";
import { getDisponibilities } from "./redux/searchAction";
import { useTranslation } from "react-i18next";

const _filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 100,
    stringify: (Cities: CitiesFromGovernment) => Cities.nom,
});

export const SearchComponent = () => {
    const [alignmentJour, setAlignmentJour] = React.useState<string | null>(
        null
    );
    const [alignmentSemaine, setAlignmentSemaine] = React.useState<
        string | null
    >(null);
    const [dayOpen, setDayOpen] = React.useState(false);
    const [startDateValue, setStartDateValue] = React.useState<Date | null>(
        null
    );
    const [weekOpen, setWeekOpen] = React.useState(false);
    const [endDateValue, setEndDateValue] = React.useState<Date | null>(null);
    const [ville, setVille] = React.useState<string>("");
    const [profession, setProfession] = React.useState<any>(null);
    const [isAutocompleteVilleOpen, setIsAutocompleteVilleOpen] =
        React.useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const translatedProfessions = GetTranslatedProfessions();
    const handleAlignmentJour = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null
    ) => {
        if (newAlignment !== null) {
            setAlignmentJour(newAlignment);
        }
    };

    const handleAlignmentSemaine = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null
    ) => {
        if (newAlignment !== null) {
            setAlignmentSemaine(newAlignment);
        }
    };

    const changeDatePicker = (x: Date | null, event: any, type: string) => {
        if (type === "week") {
            setEndDateValue(x);
        } else if (type === "day") {
            setStartDateValue(x);
        }
    };

    const filterOptions = React.useCallback((options: any, state: any) => {
        const results = _filterOptions(options, state);
        return results;
    }, []);

    function getLocalISOString(date: Date) {
        return moment(new Date(date)).toISOString(true).split("+")[0];
    }

    const submitResearch = (event: any) => {
        event.preventDefault();
        let params = "&order[createdAt]=asc";

        if (profession) {
            params += "&account.profession=" + profession;
        }
        if (ville) {
            params += "&ville=" + ville;
        }
        if (alignmentJour && alignmentJour !== "indifferent") {
            params += "&selection=" + alignmentJour;
        } else if (
            !alignmentJour &&
            alignmentSemaine &&
            alignmentSemaine !== "indifferent"
        ) {
            params += "&selection=" + alignmentSemaine;
        }
        if (
            alignmentSemaine &&
            alignmentJour &&
            alignmentSemaine !== "indifferent"
        ) {
            params += "," + alignmentSemaine;
        }
        //   params += "&selection=" + alignmentSemaine;
        // }
        if (startDateValue) {
            params +=
                // "&dateDemarrage[before]=" +
                // getLocalISOString(startDateValue) +
                "&dateFin[after]=" + getLocalISOString(startDateValue);
        }
        if (endDateValue !== null) {
            params +=
                "&dateDemarrage[before]=" +
                getLocalISOString(endDateValue) +
                "&dateFin[after]=" +
                getLocalISOString(addDays(endDateValue, 5)); // +5jours
        }
        dispatch(getDisponibilities(params));

        params = "&order[createdAt]=asc";
    };

    // eslint-disable-next-line no-extend-native
    function addDays(date: Date, days: number) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    return (
        <Stack
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
                alignItems: "center",
                color: "#FFFFF0",
                background:
                    "linear-gradient(to right top, #fdaaaa, #fda2a2, #fc9999, #fc9191, #fb8888, #f98282, #f87d7d, #f67777, #f47473, #f27070, #f06d6c, #ee6969)",
                gridTemplateAreas: `
      "title . profession profession ville ville  icon"
      "date . jour jour semaine semaine icon"`,
            }}
        >
            <Grid item sx={{ mb: 3, mt: 3 }}>
                <Typography
                    sx={{
                        gridArea: "title",
                        fontSize: "3ex",
                    }}
                >
                    {t("language.addPersonnel")}
                </Typography>
            </Grid>
            <FormContainer>
                <Stack
                    sx={{
                        flexDirection: "row",
                        gap: "1rem",

                        "@media(max-width: 1250px)": {
                            flexDirection: "column",
                        },
                    }}
                >
                    <Grid
                        item
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            height: "min-content",
                            width: "180px",
                            gridArea: "profession",
                        }}
                    >
                        <Autocomplete
                            disablePortal
                            id="profession"
                            onInputChange={(event, newInputValue) => {
                                setProfession(newInputValue);
                            }}
                            options={translatedProfessions.sort()}
                            sx={{
                                borderRadius: "5%",
                                "& fieldset": {
                                    border: "none",
                                    width: "180px",
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={t("professions:Profession")}
                                    sx={{ fontSize: "1ex" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            gridArea: "ville",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            width: "180px",
                            height: "fit-content",
                        }}
                    >
                        <Autocomplete
                            disablePortal
                            id="combo-box"
                            onInputChange={(event, newInputValue) => {
                                setVille(newInputValue);
                                if (newInputValue.length >= 2)
                                    setIsAutocompleteVilleOpen(true);
                                if (event.type === "click")
                                    setIsAutocompleteVilleOpen(false);
                            }}
                            noOptionsText={t("search.suggestion")}
                            onBlur={() => setIsAutocompleteVilleOpen(false)}
                            open={isAutocompleteVilleOpen}
                            includeInputInList
                            getOptionLabel={(option) => option.nom}
                            filterSelectedOptions
                            filterOptions={filterOptions}
                            options={Cities}
                            sx={{
                                borderRadius: "5px",
                                "& fieldset": { border: "none" },
                                width: "180px",
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="ville"
                                    id="ville"
                                    placeholder={t("search.city")}
                                />
                            )}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.code}>
                                        {option.nom}
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                </Stack>

                <Stack
                    sx={{
                        gridArea: "date",
                        display: "flex",
                        justifyContent: "center",
                        aligItems: "center",
                        gap: "1rem",
                        flexDirection: "row",

                        "@media(max-width: 1250px)": {
                            flexDirection: "column",
                        },
                    }}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr"
                    >
                        <DatePicker
                            sx={{
                                width: "180px",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                fontSize: "1ex",
                            }}
                            disablePast
                            defaultValue={new Date()}
                            open={dayOpen}
                            value={startDateValue}
                            onOpen={() => setDayOpen(true)}
                            onClose={() => setDayOpen(false)}
                            label={
                                !startDateValue ? t("select.date.start") : null
                            }
                            views={["day"]}
                            format="DD/MM/YYYY"
                            onChange={(x, event) =>
                                changeDatePicker(x, event, "day")
                            }
                            slotProps={{
                                textField: {
                                    onClick: () => setDayOpen(true),
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider
                        adapterLocale="fr"
                        dateAdapter={AdapterDayjs}
                    >
                        <DatePicker
                            sx={{
                                width: "180px",
                                backgroundColor: "white",
                                borderRadius: "5px",
                            }}
                            disablePast
                            defaultValue={new Date()}
                            open={weekOpen}
                            value={endDateValue}
                            onOpen={() => setWeekOpen(true)}
                            onClose={() => setWeekOpen(false)}
                            onChange={(x, event) =>
                                changeDatePicker(x, event, "week")
                            }
                            slotProps={{
                                textField: {
                                    onClick: () => setWeekOpen(true),
                                },
                            }}
                            label={!endDateValue ? t("select.date.end") : null}
                            format="DD/MM/YYYY"
                        />
                    </LocalizationProvider>
                </Stack>
                <Stack
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "2rem",
                        width: "fit-content",
                    }}
                >
                    <Stack
                        sx={{
                            gap: "1rem",
                            flexDirection: "row",
                            "@media(max-width: 1250px)": {
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            },
                        }}
                    >
                        <ToggleButtonGroup
                            value={alignmentJour}
                            exclusive
                            id="Jour"
                            sx={{
                                height: "55px",
                                gridArea: "jour",
                                width: "fit-content",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
                            }}
                            onChange={handleAlignmentJour}
                            color="warning"
                            aria-label="text alignment"
                        >
                            <MyToggleButton
                                value="jour"
                                aria-label="left aligned"
                                sx={{
                                    borderRadius: "5px",
                                    padding: "0 1.6rem 0 1.8rem",
                                }}
                            >
                                {t("select.day")}
                            </MyToggleButton>
                            <MyToggleButton
                                value="nuit"
                                aria-label="centered"
                                sx={{
                                    padding: "0 1.65rem 0 2rem",
                                }}
                            >
                                {t("select.night")}
                            </MyToggleButton>

                            <MyToggleButton
                                value="indifferent"
                                aria-label="right aligned"
                            >
                                {t("select.indifferent")}
                            </MyToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            value={alignmentSemaine}
                            exclusive
                            id="Semaine"
                            sx={{
                                height: "55px",
                                gridArea: "semaine",
                                width: "fit-content",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
                            }}
                            onChange={handleAlignmentSemaine}
                            color="warning"
                            aria-label="text alignment"
                        >
                            <MyToggleButton
                                value="semaines"
                                aria-label="left aligned"
                            >
                                {t("select.period.weekend")}
                            </MyToggleButton>
                            <MyToggleButton
                                value="weekend"
                                aria-label="centered"
                            >
                                Week-End
                            </MyToggleButton>
                            <MyToggleButton
                                value="indifferent"
                                aria-label="right aligned"
                            >
                                {t("select.indifferent")}
                            </MyToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    <Button
                        onClick={submitResearch}
                        sx={{
                            gridArea: "icon",
                            width: 50,
                            height: 65,
                            borderRadius: 50 / 1,
                            boxShadow:
                                "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
                            background:
                                "linear-gradient(to right top, #c1e1c1, #c2e1c3, #c3e1c5, #c5e1c7, #c6e1c9, #c6e1c9, #c6e1c9, #c6e1c9, #c5e1c7, #c3e1c5, #c2e1c3, #c1e1c1)",
                        }}
                    >
                        <img src={LoupeIcon} alt="Profile" />
                    </Button>
                </Stack>
            </FormContainer>
        </Stack>
    );
};

const MyToggleButton = styled(ToggleButton)({
    background: "white",
    boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
    borderRadius: "5px",
    fontSize: "12px",

    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#36454F",
        background:
            "linear-gradient(to right top, #c1e1c1, #c2e1c3, #c3e1c5, #c5e1c7, #c6e1c9, #c6e1c9, #c6e1c9, #c6e1c9, #c5e1c7, #c3e1c5, #c2e1c3, #c1e1c1)",
    },
    "&:hover": { backgroundColor: "#E5E4E2" },
});

const FormContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: "2rem",
    paddingBottom: "1rem",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    "@media(min-width: 1899px)": {
        gap: "4rem",
    },
});
